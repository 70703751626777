import { ColumnsDirective, Page, Inject, Search, ColumnDirective , GridComponent, Filter } from '@syncfusion/ej2-react-grids';
import { Link } from 'react-router-dom';
import React, {Component} from 'react';
import axios from 'axios';
import dogBitesData from '../../data/dogbites 22.json';
import { entriesGrid } from '../../data/dummy';
import { Header } from '../../components';
import Calendar from '@syncfusion/ej2-calendars';
import { BarSeries, Category, ChartComponent, ColumnSeries, DataLabel, Legend, LineSeries, SeriesCollectionDirective, SeriesDirective, Tooltip } from '@syncfusion/ej2-react-charts';
import Maps from '../../components/Maps';
import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';
import { AiOutlineLoading } from 'react-icons/ai';
 

const DogBite = props => (
  <tr>
    <td>{props.dogbite.username}</td>
    <td>{props.dogbite.description}</td>
    <td>{props.dogbite.duration}</td>
    <td>{props.dogbite.date.substring(0,10)}</td>
  </tr>
)

export default class DogBites extends Component {

  Tabs = ({ color }) => {
    const [openTab, setOpenTab] = React.useState(1);
    return (
      <>
        <div className="flex flex-wrap">
          <div className="w-full">
            <ul
              className="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row"
              role="tablist"
            >
              <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
                <a
                  className={
                    "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                    (openTab === 1
                      ? "text-white bg-teal-600"
                      : "text-teal-600 bg-white")
                  }
                  onClick={e => {
                    e.preventDefault();
                    setOpenTab(1);
                  }}
                  data-toggle="tab"
                  href="#link1"
                  role="tablist"
                >
                  Data Table
                </a>
              </li>
              <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
                <a
                  className={
                    "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                    (openTab === 2
                      ? "text-white bg-teal-600"
                      : "text-teal-600 bg-white")
                  }
                  onClick={e => {
                    e.preventDefault();
                    setOpenTab(2);
                  }}
                  data-toggle="tab"
                  href="#link2"
                  role="tablist"
                >
                   Charts
                </a>
              </li>
              <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
                <a
                  className={
                    "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                    (openTab === 3
                      ? "text-white bg-teal-600"
                      : "text-teal-600 bg-white")
                  }
                  onClick={e => {
                    e.preventDefault();
                    setOpenTab(3);
                  }}
                  data-toggle="tab"
                  href="#link3"
                  role="tablist"
                >
                   Map
                </a>
              </li>
            </ul>
            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
              <div className="px-4 py-5 flex-auto">
                <div className="tab-content tab-space">
                  <div className={openTab === 1 ? "block" : "hidden"} id="link1">
                  {/*
                  Tab 1
                  */}
                      <GridComponent 
                        id='dogbite'
                        dataSource={dogBitesData}
                        /*dataSource={this.state.dogbites}*/
                        allowFiltering={true}
                        allowPaging={true}
                        defaultValue={"Loading Data"}
                        filterSettings={this.filterSettings}
                        
                        width='auto'>
                        
                          <ColumnsDirective>
                            {entriesGrid.map((item, index) => (
                            /* eslint-disable react/jsx-props-no-spreading */
                            <ColumnDirective key={index} {... item}/>
                          ))}
                          <ColumnDirective field='Visit Date' headerText='Date' textAlign='left' width='40' format='yMd' />
                          <Inject
                            services={[
                            Filter,
                            Page,
                            Search,
                          ]}
                        />
                          
                          </ColumnsDirective>
                      </GridComponent>
                  </div>
                  <div className={openTab === 2 ? "block" : "hidden"} id="link2">
                    {/*
                    Tab 2
                    */}
                        {/* <ChartComponent
                        title='Dog Bites'
                        primaryXAxis={{valueType:"Category", title:"Months"}}
                        primaryYAxis={{title:"Cases"}} legendSettings={{visible:true}}
                        tooltip={{enable:true}}
                        >
                          <Inject services={[ColumnSeries, LineSeries, Category, Legend, DataLabel, Tooltip]}/>
                          <SeriesCollectionDirective>
                            <SeriesDirective
                            type='Column'
                            dataSource={dogBiteData}
                            xName='month'
                            yName='count'
                            name='Diseases'
                            marker={{dataLabel:{visible:true}, visible:true}}>

                            </SeriesDirective>
                          </SeriesCollectionDirective>
                        </ChartComponent> */}
                        <iframe width="640" height="480" src="https://charts.mongodb.com/charts-onehealth-cvjms/embed/charts?id=03b77dcd-fc42-4585-ac65-1f723c6fb1e6&maxDataAge=3600&theme=light&autoRefresh=true"></iframe>
                        <iframe width="640" height="480" src="https://charts.mongodb.com/charts-onehealth-cvjms/embed/charts?id=fe20c65f-2079-455a-8d6b-090af987aad2&maxDataAge=3600&theme=light&autoRefresh=true"></iframe>
                        <iframe width="640" height="480" src="https://charts.mongodb.com/charts-onehealth-cvjms/embed/charts?id=4541e9bc-d1df-4b12-b993-3a8e0f147a84&maxDataAge=3600&theme=light&autoRefresh=true"></iframe>
                  </div>
                  <div className={openTab === 3 ? "block" : "hidden"} id="link3">
                    {/**
                     * Tab 3
                     */}
                     <iframe width="1024" height="800" src="https://charts.mongodb.com/charts-onehealth-cvjms/embed/charts?id=9eba54e7-e1d6-4213-a71c-296706ea25b5&maxDataAge=3600&theme=light&autoRefresh=true"></iframe>
                     {/* <Maps data={[dogBitesData]}/> */}
                     {/*
                     <MapComponent data={this.state.dogbites}/>
                    */}
                     
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  

  constructor(props)
  {
    super(props);
    this.state = {dogbites :[]};
  }

  componentDidMount(){
    axios.get('api.pakonehealth.org:5000/dogbites')
    .then(response => {
      this.setState({dogbites: response.data});
      console.log("received data");

    })
    .catch((error) => {
      console.log(error);
      console.log("Not receiving data");
    })
  }

  entriesList(){
    return this.state.dogbites.map(currentEntry => {
      return <DogBite dogbites={currentEntry} />;
    })
  }
  filterSettings = { type: 'Excel' };
  fields = { text: 'text', value: 'value' };
  format = { type: 'datetime', format: 'M/d/y hh:mm a' };

  render() {
    let showPage;
    if(this.state.dogbites == null)
    {
      return(
        <div className='m-2 md:m-6 p-2 md:p-6 bg-white rounded-3xl'>
        <Header category='Zoonotic Diseases' title='Dog Bites' />  
        showPage = <div>Loading</div>
        </div>
      )
    }
    else
    {
      return(
        <div className='m-2 md:m-6 p-2 md:p-6 bg-white rounded-3xl'>
        <Header category='Zoonotic Diseases' title='Dog Bites' />  
        <this.Tabs color="teal"/>
      </div>
      )
      }
  }
}