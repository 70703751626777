import React, {Component, useMemo} from 'react';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Toolbar,
  Search,
  Resize,
  Sort,
  ContextMenu,
  Filter,
  Page,
  Edit,
  Inject,
} from '@syncfusion/ej2-react-grids';
import { entriesGrid } from '../../data/dummy';
import { Header, Button } from '../../components';
import axios from 'axios';
import { GoogleMap, Marker, withScriptjs, withGoogleMap } from 'react-google-maps';
import MarkerClusterer from 'react-google-maps/lib/components/addons/MarkerClusterer';
import mapStylesSilver from '../../components/mapStylesSilver';

const Anthrax = props => (
  <tr>
    <td>{props.anthrax.username}</td>
    <td>{props.anthrax.description}</td>
    <td>{props.anthrax.duration}</td>
    <td>{props.anthrax.date.substring(0,10)}</td>
  </tr>
)

const thisState = {};

const defaultOptions = {
  strokeOpacity: 0.5,
  strokeWeight:2,
  clickable:false,
  draggable:false,
  editable:false,
  visible:true,
};

const diseasesLatLng = {};

const criticalOptions = {
  ...defaultOptions,
  zIndex:2,
  fillOpacity:0.05,
  strokeColour:'#FF5252',
  fillColour:'#FF5252',
};


{/*
function Map(){
  return(
  <GoogleMap
  defaultZoom={10}
  defaultCenter={{lat: 30.123456,lng: 72.456789}}
  defaultOptions={{ styles: mapStylesSilver }}
  clickableIcons={true}
  >
    {thisState.anthrax.map((point) => 
    <Marker
    key={point._id}
    position={
      {
        lat:point.Latitude,
        lng:point.Longitutde
      }}
    />
    )}
  </GoogleMap>
  ) 
}
*/}
const WrappedMap = withScriptjs(withGoogleMap(Map))

export default class Anthraxes extends Component{
  constructor(props)
  {
    super(props);
    this.state = {anthraxes :[]};
  }
  
  Tabs = ({ color }) => {
    const [openTab, setOpenTab] = React.useState(1);
    return (
      <>
        <div className="flex flex-wrap">
          <div className="w-full">
            <ul
              className="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row"
              role="tablist"
            >
              <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
                <a
                  className={
                    "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                    (openTab === 1
                      ? "text-white bg-teal-600"
                      : "text-teal-600 bg-white")
                  }
                  onClick={e => {
                    e.preventDefault();
                    setOpenTab(1);
                  }}
                  data-toggle="tab"
                  href="#link1"
                  role="tablist"
                >
                  Data Table
                </a>
              </li>
              <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
                <a
                  className={
                    "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                    (openTab === 2
                      ? "text-white bg-teal-600"
                      : "text-teal-600 bg-white")
                  }
                  onClick={e => {
                    e.preventDefault();
                    setOpenTab(2);
                  }}
                  data-toggle="tab"
                  href="#link2"
                  role="tablist"
                >
                   Charts
                </a>
              </li>
              <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
                <a
                  className={
                    "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                    (openTab === 3
                      ? "text-white bg-teal-600"
                      : "text-teal-600 bg-white")
                  }
                  onClick={e => {
                    e.preventDefault();
                    setOpenTab(3);
                  }}
                  data-toggle="tab"
                  href="#link3"
                  role="tablist"
                >
                   Map
                </a>
              </li>
            </ul>
            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
              <div className="px-4 py-5 flex-auto">
                <div className="tab-content tab-space">
                  <div className={openTab === 1 ? "block" : "hidden"} id="link1">
                  {/*
                  Tab 1
                  */}
                    <GridComponent 
                    id='anthrax'
                    dataSource={this.state.anthrax}
                    allowFiltering={true}
                    allowPaging={true}
                    filterSettings={this.filterSettings}
                    width='auto'>
                      <ColumnsDirective>
                        {entriesGrid.map((item, index) => (
                        /* eslint-disable react/jsx-props-no-spreading */
                        <ColumnDirective key={index} {... item}/>
                      ))}
                      <Inject
                        services={[
                        Toolbar,
                        Filter,
                        ContextMenu,
                        Page,
                        Search,
                      ]}
                    />
                      
                      </ColumnsDirective>
                    </GridComponent>
                  </div>
                  <div className={openTab === 2 ? "block" : "hidden"} id="link2">
                    {/*
                    Tab 2
                    */}
                      <div>
                      <iframe width="640" height="480" src="https://charts.mongodb.com/charts-project-0-oudwg/embed/charts?id=4eb45b25-1a12-4d0c-a9e6-346e9a8d23ea&maxDataAge=3600&theme=light&autoRefresh=true"></iframe>

                      <iframe width="640" height="480" src="https://charts.mongodb.com/charts-project-0-oudwg/embed/charts?id=ba07d775-7b94-4073-a870-102cc224cd47&maxDataAge=3600&theme=light&autoRefresh=true"></iframe>

                      <iframe width="640" height="480" src="https://charts.mongodb.com/charts-project-0-oudwg/embed/charts?id=62fcab45-4794-4a24-8f63-140f43466425&maxDataAge=3600&theme=light&autoRefresh=true"></iframe>
                      {/*style="background: #FFFFFF;border: none;border-radius: 2px;box-shadow: 0 2px 10px 0 rgba(70, 76, 79, .2);"*/}
                      </div>


                  </div>
                  <div className={openTab === 3 ? "block" : "hidden"} id="link3">
                    {/**
                     * Tab 3
                     */}
                     {/*
                     <MapComponent data={this.state.anthrax}/>
                    


                    <WrappedMap
                      googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${
                        process.env.REACT_APP_GOOGLE_KEY
                      }`}
                      loadingElement={<div style={{ height: `100%` }} />}
                      containerElement={<div style={{ height: `100%` }} />}
                      mapElement={<div style={{ height: `100%` }} />}
                    />
                    */} 
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  componentDidMount(){
    axios.get('https://api.pakonehealth.org/anthraxes')
    .then(response => {
      this.setState({anthrax: response.data});
      {/*
      diseasesLatLng = response.data.map(disease => {
        return {
              type:"String",
              Lat:disease.Latitude,
              Long:disease.Longitutde,
              _id:disease._id
        }
      });
      thisState.setState({anthrax: response.data});
      console.log(diseasesLatLng);
      */}
      console.log("received data");

    })
    .catch((error) => {
      console.log(error);
      console.log("Not receiving data");
    })
  }

  renderMap(){
    
  }

  Map(){
      return(
      <GoogleMap
      defaultZoom={10}
      defaultCenter={{lat: 30.123456,lng: 72.456789}}
      defaultOptions={{ styles: mapStylesSilver }}
      clickableIcons={true}
      >
        {diseasesLatLng.map((point) => 
        <Marker
        key={point._id}
        position={
          {
            lat:point.Lat,
            lng:point.Long
          }}
        />
        )}
      </GoogleMap>
      )
  }
  
  entriesList(){
    return this.state.anthrax.map(currentEntry => {
      return <Anthrax anthrax={currentEntry} />;
    })
  }

  filterSettings = { type: 'Excel' };
  fields = { text: 'text', value: 'value' };
  format = { type: 'datetime', format: 'M/d/y hh:mm a' };
  render() {
    return (    
    <div className='m-2 md:m-6 p-2 md:p-6 bg-white rounded-3xl'>
    <Header category='Zoonotic Diseases' title='Anthrax' />

      <this.Tabs color={'teal'}/>
        </div>
    )
  }
}