import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const DatePickerComponent = ({ onSelectDate, header }) => {
  const [selectedDate, setSelectedDate] = useState(null);
  
  const handleDateChange = (date) => {
    setSelectedDate(date);
    onSelectDate(date); // Call the prop function to send the selected date back to parent
  };

  return (
    <div>
      <h1>{header}</h1>
      <DatePicker
        selected={selectedDate}
        onChange={handleDateChange}
        dateFormat="MM/dd/yyyy"
      />
    </div>
  );
};

export default DatePickerComponent;
