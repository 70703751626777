import React from 'react';
import { AiOutlineAreaChart, AiOutlineBarChart } from 'react-icons/ai';
import { FiPieChart, FiBarChart, FiCreditCard, FiStar, FiMap } from 'react-icons/fi';
import { BsBoxSeam, BsCurrencyDollar, BsShield } from 'react-icons/bs';
import { MdOutlineSupervisorAccount, MdHome, MdDataUsage, MdReport } from 'react-icons/md';
import { HiOutlineRefresh } from 'react-icons/hi';
import { GrLocation } from 'react-icons/gr';
import source from './source.png';
import { documentLogo, dhis2Logo, covidLogo, adrsLogo, publicationLogo,Promed, Medisys, pohaLogo, nihLogo, districtLogo, locationLogo, hospitalLogo } from '../images';
import { anthraxBlack, covidBlack, amebiasisBlack, cchfBlack, dogbiteBlack,tbBlack, malariaBlack, dengueBlack } from '../images/diseasesicons';
import { who, districtBlack, ep, epLogo, gopGreen, gopGrey, nihBlue, pspu, punjab, uhs, uhsGrey, uvas} from '../images/icons'
// import dssLogo from '../images/dssLogo.png';
// import misLogo from '../images/misLogo.png';

export const MapLatLng1 = [
    {
      district: "Attock",
      label: "DHQ Hospital Attock",
      lat: 33.7667,
      lng: 72.3573
    },
    {
      district: "Attock",
      label: "THQ Hassan Abdal",
      lat: 33.8074,
      lng: 72.7168
    },
    {
      district: "Attock",
      label: "THQ Hospital Fateh Jang",
      lat: 33.6005,
      lng: 72.8766
    },
    {
      district: "Attock",
      label: "THQ Hospital Hazro",
      lat: 33.6777,
      lng: 72.6552
    },
    {
      district: "Attock",
      label: "THQ Hospital Jand",
      lat: 33.5513,
      lng: 72.3427
    },
    {
      district: "Attock",
      label: "THQ Hospital Pindi Gheb",
      lat: 33.2563,
      lng: 72.2591
    },
    {
      district: "Bahawalpur",
      label: "BHU BASTI RANA IQBAL",
      lat: 29.3787,
      lng: 71.6871
    },
    {
      district: "Bahawalpur",
      label: "BHU CHAK NO. 39/DNB",
      lat: 29.3493,
      lng: 71.7487
    },
    {
      district: "Bahawalpur",
      label: "BHU CHAK NO. 88/F",
      lat: 29.3523,
      lng: 71.7201
    },
    {
      district: "Bahawalpur",
      label: "BHU CHAK NO.8/DNB",
      lat: 29.3338,
      lng: 71.7189
    },    
    {
      district: "Bhakkar",
      label: "DHQ Hospital Bhakkar",
      lat: 31.629717,
      lng: 71.065888
    },
    {
      district: "Bhakkar",
      label: "THQ Hospital Daryakhan",
      lat: 31.906372,
      lng: 70.826623
    },
    {
      district: "Bhakkar",
      label: "THQ Hospital Kalurkot",
      lat: 31.980346,
      lng: 71.260879
    },
    {
      district: "Bhakkar",
      label: "THQ Hospital Mankera",
      lat: 32.057827,
      lng: 70.732418
    },
    {
      district: "Bhakkar",
      label: "edo Office Bhakkar",
      lat: 31.630648,
      lng: 71.065304
    },
    {
      district: "Chakwal",
      label: "BHU NEELA",
      lat: 32.963746,
      lng: 72.874182
    },
    {
      district: "Chakwal",
      label: "Ch. Pervaiz Elahi City Hospital Talagang",
      lat: 32.932144,
      lng: 72.920374
    },
    {
      district: "Chakwal",
      label: "DHQ Chakwal",
      lat: 32.927824,
      lng: 72.848177
    },
    {
      district: "Chakwal",
      label: "THQ Choa Saiden Shah",
      lat: 32.749702,
      lng: 72.864616
    },
    {
      district: "Chakwal",
      label: "THQ TALAGANG",
      lat: 32.932144,
      lng: 72.920374
    },
    {
      district: "Chakwal",
      label: "THQ Trauma Kallar Kahar",
      lat: 32.807137,
      lng: 72.663232
    },
    {
      district: "Chakwal",
      label: "edo Office Chakwal",
      lat: 32.927268,
      lng: 72.847821
    },
    {
      district: "Chiniot",
      label: "BHU 241 Mangoana",
      lat: 31.722489,
      lng: 72.979608
    },
    {
      district: "Chiniot",
      label: "BHU AHMED ABAD",
      lat: 31.676819,
      lng: 72.887809
    },
    {
      district: "Chiniot",
      label: "BHU Mathrooma",
      lat: 31.760183,
      lng: 72.788151
    },
    {
      district: "Chiniot",
      label: "DHQ Hospital Chiniot",
      lat: 31.720366,
      lng: 72.978161
    },
    {
      district: "Chiniot",
      label: "THQ Bhowana",
      lat: 31.682414,
      lng: 72.662964
    },
    {
      district: "Chiniot",
      label: "THQ Lalian",
      lat: 31.747577,
      lng: 72.905697
    },
    {
      district: "Chiniot",
      label: "edo Office Chiniot",
      lat: 31.720692,
      lng: 72.980123
    },
    {
      district: "Dera Ghazi Khan",
      label: "BHU KHAKHI",
      lat: 30.924639,
      lng: 70.964286
    },
    {
      district: "Dera Ghazi Khan",
      label: "RHC CHOTI ZARIN",
      lat: 30.982593,
      lng: 70.769347
    },
    {
      district: "Dera Ghazi Khan",
      label: "RHC Kala",
      lat: 30.735014,
      lng: 71.472885
    },
    {
      district: "Dera Ghazi Khan",
      label: "RHC SARWAR WALI",
      lat: 30.906819,
      lng: 71.138524
    },
    {
      district: "Dera Ghazi Khan",
      label: "RHC SHAH SADAR DIN",
      lat: 30.927717,
      lng: 71.213008
    },
    {
      district: "Dera Ghazi Khan",
      label: "RHC SHAHDAN LUND",
      lat: 30.694942,
      lng: 71.043277
    },
    {
      district: "Dera Ghazi Khan",
      label: "RHC TIBBI QASRANI",
      lat: 30.579363,
      lng: 70.943368
    },
    {
      district: "Dera Ghazi Khan",
      label: "RHC VEHOVA",
      lat: 30.593043,
      lng: 71.281638
    },
    {
      district: "Dera Ghazi Khan",
      label: "THQ Hospital Kot Chutta",
      lat: 30.587181,
      lng: 70.931536
    },
    {
      district: "Dera Ghazi Khan",
      label: "THQ Hospital Taunsa",
      lat: 30.695581,
      lng: 70.657625
    },
    {
      district: "Faisalabad",
      label: "Al-Shifa Medical Centre Faisalabad",
      lat: 31.400104,
      lng: 73.081648
    },
    {
      district: "Faisalabad",
      label: "Allied Hospital, Jail Road, Faisalabad",
      lat: 31.401801,
      lng: 73.077906
    },
    {
      district: "Faisalabad",
      label: "Children Hospital, Faisalabad",
      lat: 31.410077,
      lng: 73.085969
    },
    {
      district: "Faisalabad",
      label: "DHQ Hospital, Mall Road, Faisalabad",
      lat: null,
      lng: null
    },
    {
      district: "Faisalabad",
      label: "Government Teaching Hospital, Ghulam Muhammad Abad, Faisalabad",
      lat: null,
      lng: null
    },
    {
      district: "Faisalabad",
      label: "Govt. General Hospital Samanabad",
      lat: null,
      lng: null
    },
    {
      district: "Faisalabad",
      label: "RHC 469 GB",
      lat: null,
      lng: null
    },
    {
      district: "Faisalabad",
      label: "RHC SATYANA",
      lat: null,
      lng: null
    },
    {
      district: "Faisalabad",
      label: "THQ Hospital Jaranwala",
      lat: null,
      lng: null
    },
    {
      district: "Faisalabad",
      label: "THQ Hospital Jhumra",
      lat: null,
      lng: null
    },
    {
      district: "Faisalabad",
      label: "THQ Hospital Samanduri",
      lat: null,
      lng: null
    },
    {
      district: "Faisalabad",
      label: "THQ Tandilianwala",
      lat: null,
      lng: null
    },
      {
        district: "Gujranwala",
        label: "Al Raee Hospital",
        lat: 32.167929,
        lng: 74.170005
      },
      {
        district: "Gujranwala",
        label: "BHU BOTALA SHARAM SINGH",
        lat: 32.275698,
        lng: 74.184014
      },
      {
        district: "Gujranwala",
        label: "BHU BUDDA GORRAYA",
        lat: 32.180647,
        lng: 74.156509
      },
      {
        district: "Gujranwala",
        label: "BHU CHAK RAMDAS",
        lat: 32.249578,
        lng: 74.201868
      },
      {
        district: "Gujranwala",
        label: "BHU DANDIAN",
        lat: 32.247700,
        lng: 74.179812
      },
      {
        district: "Gujranwala",
        label: "BHU DARGA PUR",
        lat: null,
        lng: null
      },
      {
        district: "Gujranwala",
        label: "BHU FEROZEWALA",
        lat: null,
        lng: null
      },
      {
        district: "Gujranwala",
        label: "BHU KALI SUBA",
        lat: null,
        lng: null
      },
      {
        district: "Gujranwala",
        label: "BHU LALU PUR",
        lat: null,
        lng: null
      },
      {
        district: "Gujranwala",
        label: "BHU MOKHAL SINDAAWAN",
        lat: null,
        lng: null
      },
      {
        district: "Gujranwala",
        label: "BHU PEERO CHAK",
        lat: null,
        lng: null
      },
      {
        district: "Gujranwala",
        label: "BHU RAJA",
        lat: null,
        lng: null
      },
      {
        district: "Gujranwala",
        label: "DHQ Teaching Hospital, Gujranwala",
        lat: null,
        lng: null
      },
      {
        district: "Gujranwala",
        label: "Medcare International Hospital",
        lat: null,
        lng: null
      },
      {
        district: "Gujranwala",
        label: "RHC AHMAD NAGAR",
        lat: null,
        lng: null
      },
      {
        district: "Gujranwala",
        label: "RHC ALI PUR CHATHA",
        lat: null,
        lng: null
      },
      {
        district: "Gujranwala",
        label: "RHC EMINABAD",
        lat: null,
        lng: null
      },
      {
        district: "Gujranwala",
        label: "RHC GHAKHAR",
        lat: null,
        lng: null
      },
      {
        district: "Gujranwala",
        label: "RHC Kot Ladha",
        lat: null,
        lng: null
      },
      {
        district: "Gujranwala",
        label: "RHC Ladhey Wala Waraich",
        lat: null,
        lng: null
      },
      {
        district: "Gujranwala",
        label: "RHC RASOOL NAGAR",
        lat: null,
        lng: null
      },
      {
        district: "Gujranwala",
        label: "RHC SOHDRA",
        lat: null,
        lng: null
      },
      {
        district: "Gujranwala",
        label: "RHC WAHNDO",
        lat: null,
        lng: null
      },
      {
        district: "Gujranwala",
        label: "THQ Hospital Kamoke",
        lat: null,
        lng: null
      },
      {
        district: "Gujranwala",
        label: "THQ Hospital Wazirabad",
        lat: null,
        lng: null
      },
      {
        district: "Gujranwala",
        label: "THQ Noshera Virkan",
        lat: null,
        lng: null
      },
      {
        district: "Gujranwala",
        label: "Zeeshan Welfare Hospital",
        lat: null,
        lng: null
      },
      {
        district: "Gujranwala",
        label: "edo Office Gujranwala",
        lat: null,
        lng: null
      },
      {
        district: "Gujrat",
        label: "Aziz Bhatti Shaheed Hospital (DHQ), Bhimber Road, Gujrat",
        lat: 32.574023,
        lng: 74.096446
      },
      {
        district: "Gujrat",
        label: "BHU BHAG NAGAR",
        lat: 32.579384,
        lng: 74.107064
      },
      {
        district: "Gujrat",
        label: "BHU BOLANI",
        lat: 32.560043,
        lng: 74.145186
      },
      {
        district: "Gujrat",
        label: "BHU CHECHIAN SHAMAS",
        lat: 32.542369,
        lng: 74.096225
      },
      {
        district: "Gujrat",
        label: "BHU CHIRYAWALA",
        lat: 32.615675,
        lng: 74.027587
      },
      {
        district: "Gujrat",
        label: "BHU DENDAI BESA",
        lat: 32.624501,
        lng: 74.013499
      },
      {
        district: "Gujrat",
        label: "BHU GHAKHRA KALAN",
        lat: 32.556492,
        lng: 74.082202
      },
      {
        district: "Gujrat",
        label: "BHU KARYALA",
        lat: 32.559456,
        lng: 74.022407
      },
      {
        district: "Gujrat",
        label: "BHU KHOHAR",
        lat: 32.597125,
        lng: 74.023193
      },
      {
        district: "Gujrat",
        label: "BHU LAKHANWAL",
        lat: 32.542914,
        lng: 74.062688
      },
      {
        district: "Gujrat",
        label: "BHU MAKIANA",
        lat: 32.526755,
        lng: 74.068735
      },
      {
        district: "Gujrat",
        label: "BHU SIR DHOKE",
        lat: 32.572008,
        lng: 74.076691
      },
      {
        district: "Gujrat",
        label: "BHU THOOTHA RAI BAHADUR",
        lat: 32.540779,
        lng: 74.085575
      },
      {
        district: "Gujrat",
        label: "RHC MALKA",
        lat: 32.574137,
        lng: 74.130855
      },
      {
        district: "Gujrat",
        label: "THQ Hospital Kharian",
        lat: 32.820502,
        lng: 73.862493
      },
      {
        district: "Gujrat",
        label: "THQ Hospital Kunjah",
        lat: 32.573207,
        lng: 74.241104
      },
      {
        district: "Gujrat",
        label: "THQ Hospital Lalamusa",
        lat: 32.706463,
        lng: 73.978992
      },
      {
        district: "Gujrat",
        label: "THQ Hospital Sarai Alamgir",
        lat: 32.920547,
        lng: 74.638079
      },
      {
        district: "Gujrat",
        label: "THQ Hospital Trauma Center Lalamusa",
        lat: 32.707021,
        lng: 73.977303
      },
      {
        district: "Hafizabad",
        label: "BHU Kot Nawan Kaghoka",
        lat: 32.263828,
        lng: 73.772252
      },
      {
        district: "Hafizabad",
        label: "DHQ Hospital Hafizabad",
        lat: 32.073266,
        lng: 73.686682
      },
      {
        district: "Hafizabad",
        label: "RHC Jalal Pur Bhattian",
        lat: 32.294309,
        lng: 73.862272
      },
      {
        district: "Hafizabad",
        label: "RHC Kaleke Mandi",
        lat: 32.247314,
        lng: 73.755463
      },
      {
        district: "Hafizabad",
        label: "RHC Kasooke",
        lat: 32.370829,
        lng: 73.808282
      },
      {
        district: "Hafizabad",
        label: "RHC Kolo Tarar",
        lat: 32.144898,
        lng: 73.903791
      },
      {
        district: "Hafizabad",
        label: "RHC Rasool Pur Tarar",
        lat: null,
        lng: null
      },
      {
        district: "Hafizabad",
        label: "RHC Sukheke Mandi",
        lat: null,
        lng: null
      },
      {
        district: "Hafizabad",
        label: "RHC Vanike Tarar",
        lat: null,
        lng: null
      },
      {
        district: "Hafizabad",
        label: "THQ Pindi Bhattian",
        lat: null,
        lng: null
      },
      {
        district: "Hafizabad",
        label: "Wahlah Family Hospital",
        lat: null,
        lng: null
      },
      {
        district: "Hafizabad",
        label: "edo Office Hafizabad",
        lat: null,
        lng: null
      },
      {
        district: "Jhang",
        label: "Chak No.170/JB",
        lat: null,
        lng: null
        },
        {
        district: "Jhang",
        label: "DHQ Hospital Jhang",
        lat: 31.276458,
        lng: 72.315813
        },
        {
        district: "Jhang",
        label: "Ismail Welfare Hospital",
        lat: 31.275751,
        lng: 72.328571
        },
        {
        district: "Jhang",
        label: "RHC Mochiwala",
        lat: null,
        lng: null
        },
        {
        district: "Jhang",
        label: "RHC Shah Jewena",
        lat: 31.202786,
        lng: 72.296371
        },
        {
        district: "Jhang",
        label: "Ratta Matta",
        lat: null,
        lng: null
        },
        {
        district: "Jhang",
        label: "THQ Ahmed pur Sial",
        lat: 31.938361,
        lng: 72.581833
        },
        {
        district: "Jhang",
        label: "THQ Hospital Hazari",
        lat: 31.447497,
        lng: 72.420362
        },
        {
        district: "Jhang",
        label: "THQ Hospital Shorkot",
        lat: 31.526281,
        lng: 72.271556
        },
        {
        district: "Jhang",
        label: "edo Office Jhang",
        lat: null,
        lng: null
        },
        {
        district: "Jhelum",
        label: "BHU Adrana",
        lat: 32.942671,
        lng: 73.566586
        },
        {
        district: "Jhelum",
        label: "BHU Ahmad Abad",
        lat: 32.989585,
        lng: 73.731681
        },
        {
        district: "Jhelum",
        label: "BHU Ban Shaheed",
        lat: 32.992651,
        lng: 73.751894
        },
        {
        district: "Jhelum",
        label: "BHU Banth",
        lat: 32.964148,
        lng: 73.690734
        },
        {
        district: "Jhelum",
        label: "BHU Bhatia",
        lat: 32.939979,
        lng: 73.764949
        },
        {
        district: "Jhelum",
        label: "BHU Chak Jani",
        lat: 32.949693,
        lng: 73.765641
        },
        {
        district: "Jhelum",
        label: "BHU Chak Khasa/ Ph # 0544-732489",
        lat: 32.970795,
        lng: 73.748309
        },
        {
        district: "Jhelum",
        label: "BHU Chak Latif Ullah",
        lat: 32.983403,
        lng: 73.827335
        },
        {
        district: "Jhelum",
        label: "BHU Chak Shadi",
        lat: 32.973694,
        lng: 73.825602
        },
        {
        district: "Jhelum",
        label: "BHU Chotala",
        lat: 32.985577,
        lng: 73.742412
        },
        {
        district: "Jhelum",
        label: "BHU Dara Pur",
        lat: 32.999411,
        lng: 73.748925
        },
        {
        district: "Jhelum",
        label: "BHU Dharyala Jalip",
        lat: 32.998698,
        lng: 73.827624
        },
        {
        district: "Jhelum",
        label: "BHU G U Singh",
        lat: 32.944886,
        lng: 73.777542
        },
        {
        district: "Jhelum",
        label: "BHU Goal Pur",
        lat: 32.937578,
        lng: 73.806214
        },
        {
        district: "Jhelum",
        label: "BHU Huran Pur",
        lat: 32.933188,
        lng: 73.736454
        },
        {
        district: "Jhelum",
        label: "BHU Jallo Chak",
        lat: 32.997829,
        lng: 73.715819
        },
        {
        district: "Jhelum",
        label: "BHU Jango Raryala",
        lat: 32.999079,
        lng: 73.708079
        },
        {
        district: "Jhelum",
        label: "BHU Kala Gujran",
        lat: 32.919786,
        lng: 73.735728
        },
        {
        district: "Jhelum",
        label: "BHU Kalvant Pur",
        lat: 32.965131,
        lng: 73.777346
        },
        {
        district: "Jhelum",
        label: "BHU Krounta",
        lat: 32.968867,
        lng: 73.705075
        },
        {
        district: "Jhelum",
        label: "BHU Kundwal",
        lat: 32.924433,
        lng: 73.807844
        },
        {
        district: "Jhelum",
        label: "BHU Mota Gharbi",
        lat: 32.920891,
        lng: 73.774711
        },
        {
        district: "Jhelum",
        label: "BHU Pail Mirza",
        lat: 32.997717,
        lng: 73.756967
        },
        {
        district: "Jhelum",
        label: "BHU Phulray Sayedan",
        lat: 32.971837,
        lng: 73.780059
        },
        {
        district: "Jhelum",
        label: "BHU Pindi Said Pur",
        lat: 32.994987,
        lng: 73.777276
        },
        {
        district: "Jhelum",
        label: "BHU Pindory",
        lat: 32.957384,
        lng: 73.705135
        },
        {
        district: "Jhelum",
        label: "BHU Sodi Gujjar",
        lat: 32.958872,
        lng: 73.797716
        },
        {
        district: "Jhelum",
        label: "BHU Sohan",
        lat: 32.955505,
        lng: 73.734495
        },
        {
        district: "Jhelum",
        label: "BHU Souwal",
        lat: 32.97269,
        lng: 73.71566
        },
        {
        district: "Jhelum",
        label: "DHQ Hospital Jhelum",
        lat: 32.93203,
        lng: 73.737254
        },
        {
        district: "Jhelum",
        label: "RHC Civil Hospital, Khewra, Pind Dadan Khan, Jhelum",
        lat: null,
        lng: null
        },
        {
        district: "Jhelum",
        label: "RHC Dina",
        lat: 32.77838,
        lng: 73.648854
        },
        {
        district: "Jhelum",
        label: "RHC Domeli",
        lat: 32.921421,
        lng: 73.742224
        },
        {
        district: "Jhelum",
        label: "RHC Jalal Pur Sharif",
        lat: 32.944445,
        lng: 73.774265
        },
        {
        district: "Jhelum",
        label: "RHC Khalas Pur",
        lat: 32.898549,
        lng: 73.819028
        },
        {
        district: "Jhelum",
        label: "RHC Lilla",
        lat: 32.996165,
        lng: 73.729045
        },
        {
        district: "Jhelum",
        label: "THQ Hospital PD Khan",
        lat: 32.749486,
        lng: 73.542297
        },
        {
        district: "Jhelum",
        label: "THQ Hospital Sohawa",
        lat: 33.065832,
        lng: 73.424048
        },
        {
        district: "Jhelum",
        label: "edo Office Jhelum",
        lat: null,
        lng: null
        },
        {
          district: "Kasur",
          label: "BHU KHARA",
          lat: 31.195016,
          lng: 74.384675
        },
        {
          district: "Kasur",
          label: "DHQ HOSPITAL KASUR",
          lat: 31.116663,
          lng: 74.448994
        },
        {
          district: "Kasur",
          label: "Govt. Aziz Bibi Hospital Roshan Bhila",
          lat: 31.219118,
          lng: 74.339438
        },
        {
          district: "Kasur",
          label: "RHC CHANGA MANGA",
          lat: 30.661223,
          lng: 73.775543
        },
        {
          district: "Kasur",
          label: "RHC ELLAH ABAD.",
          lat: 31.113057,
          lng: 74.470961
        },
        {
          district: "Kasur",
          label: "RHC GANDA SINGH WALA",
          lat: 31.200422,
          lng: 74.468572
        },
        {
          district: "Kasur",
          label: "RHC HABIBABAD",
          lat: 31.165219,
          lng: 74.099454
        },
        {
          district: "Kasur",
          label: "RHC HALLA",
          lat: 31.021551,
          lng: 74.026725
        },
        {
          district: "Kasur",
          label: "RHC JUARA",
          lat: 30.927769,
          lng: 74.290782
        },
        {
          district: "Kasur",
          label: "RHC KANGAN PUR",
          lat: 31.049389,
          lng: 74.373258
        },
        {
          district: "Kasur",
          label: "RHC KHUDIAN",
          lat: 31.153021,
          lng: 74.270716
        },
        {
          district: "Kasur",
          label: "RHC MUSTAFA ABAD",
          lat: 31.117295,
          lng: 74.382743
        },
        {
          district: "Kasur",
          label: "RHC PHOOL NAGAR",
          lat: 31.091007,
          lng: 74.247077
        },
        {
          district: "Kasur",
          label: "RHC RAJA JANG",
          lat: 31.110661,
          lng: 74.406913
        },
        {
          district: "Kasur",
          label: "THQ HOSPITAL CHUNIAN",
          lat: 31.178666,
          lng: 73.960455
        },
        {
          district: "Kasur",
          label: "THQ HOSPITAL PATTOKI",
          lat: 31.044397,
          lng: 73.853182
        },
        {
          district: "Kasur",
          label: "THQ Kot Radha Kishan",
          lat: 31.062668,
          lng: 73.958606
        },
        {
          district: "Khanewal",
          label: "BHU CHAK NO. 67/10-R",
          lat: 30.218131,
          lng: 71.911868
        },
        {
          district: "Khanewal",
          label: "BHU CHAK NO. 70/15-L",
          lat: 30.186596,
          lng: 71.834333
        },
        {
          district: "Khanewal",
          label: "BHU CHAK NO. 92/10-R",
          lat: 30.301253,
          lng: 71.944263
        },
        {
          district: "Khanewal",
          label: "BHU CHAK NO.139/10-R",
          lat: 30.354403,
          lng: 72.091937
        },
        {
          district: "Khanewal",
          label: "BHU CHAK NO.9/V",
          lat: 30.345079,
          lng: 72.073482
        },
        {
          district: "Khanewal",
          label: "BHU JASSO KANWAN",
          lat: 30.286963,
          lng: 71.793801
        },
        {
          district: "Khanewal",
          label: "BHU SARDAR PUR",
          lat: 30.247225,
          lng: 71.716625
        },
        {
          district: "Khanewal",
          label: "BHU SHAM KOT",
          lat: 30.275697,
          lng: 71.777952
        },
        {
          district: "Khanewal",
          label: "DHQ Hospital Khanewal",
          lat: 30.297778,
          lng: 71.932778
        },
        {
          district: "Khanewal",
          label: "RHC KACHA KHUH",
          lat: 30.360727,
          lng: 71.954778
        },
        {
          district: "Khanewal",
          label: "RHC Makhdoom Pur, Khanewal, Khanewal",
          lat: 30.260744,
          lng: 71.891834
        },
        {
          district: "Khanewal",
          label: "THQ Hospital Channu",
          lat: 30.699398,
          lng: 72.678589
        },
        {
          district: "Khanewal",
          label: "THQ Hospital Jhanian",
          lat: 30.530129,
          lng: 71.830259
        },
        {
          district: "Khanewal",
          label: "THQ Hospital Kabir wala",
          lat: 30.733298,
          lng: 72.647488
        },
        {
          district: "Khanewal",
          label: "edo Office Kkhanewal",
          lat: 30.301899,
          lng: 71.941334
        },
        {
          district: "Khushab",
          label: "DHQ Khushab at Jahurabad",
          lat: 32.347687,
          lng: 72.369992
        },
        {
          district: "Khushab",
          label: "THQ HOSPITAL NOOR PUR THAL",
          lat: 32.465695,
          lng: 71.875035
        },
        {
          district: "Khushab",
          label: "THQ HOSPITAL QAIDABAD",
          lat: 32.276212,
          lng: 72.027415
        },
        {
          district: "Khushab",
          label: "edo Office Khushab",
          lat: 32.297855,
          lng: 72.344289
        },
        {
          district: "Kasur",
          label: "BHU KHARA",
          lat: 31.128389,
          lng: 74.252042
        },
        {
          district: "Kasur",
          label: "DHQ HOSPITAL KASUR",
          lat: 31.119895,
          lng: 74.454548
        },
        {
          district: "Kasur",
          label: "Govt. Aziz Bibi Hospital Roshan Bhila",
          lat: 31.122911,
          lng: 74.445066
        },
        {
          district: "Kasur",
          label: "RHC CHANGA MANGA",
          lat: 31.284396,
          lng: 73.978188
        },
        {
          district: "Kasur",
          label: "RHC ELLAH ABAD.",
          lat: 31.066836,
          lng: 74.271137
        },
        {
          district: "Kasur",
          label: "RHC GANDA SINGH WALA",
          lat: 31.030836,
          lng: 74.447084
        },
        {
          district: "Kasur",
          label: "RHC HABIBABAD",
          lat: 31.030892,
          lng: 74.156905
        },
        {
          district: "Kasur",
          label: "RHC HALLA",
          lat: 31.116898,
          lng: 74.159542
        },
        {
          district: "Kasur",
          label: "RHC JUARA",
          lat: 31.138395,
          lng: 74.077068
        },
        {
          district: "Kasur",
          label: "RHC KANGAN PUR",
          lat: 31.26931,
          lng: 74.373583
        },
        {
          district: "Kasur",
          label: "RHC KHUDIAN",
          lat: 31.217228,
          lng: 74.241036
        },
        {
          district: "Kasur",
          label: "RHC MUSTAFA ABAD",
          lat: 31.147216,
          lng: 74.067042
        },
        {
          district: "Kasur",
          label: "RHC PHOOL NAGAR",
          lat: 31.30416,
          lng: 74.205591
        },
        {
          district: "Kasur",
          label: "RHC RAJA JANG",
          lat: 31.192127,
          lng: 74.333404
        },
        {
          district: "Kasur",
          label: "THQ HOSPITAL CHUNIAN",
          lat: 31.117982,
          lng: 73.985056
        },
        {
          district: "Kasur",
          label: "THQ HOSPITAL PATTOKI",
          lat: 31.045992,
          lng: 73.855005
        },
        {
          district: "Kasur",
          label: "THQ Kot Radha Kishan",
          lat: 31.03295,
          lng: 74.214152
        },
        {
          district: "Khanewal",
          label: "BHU CHAK NO. 67/10-R",
          lat: 30.211223,
          lng: 71.952813
        },
        {
          district: "Khanewal",
          label: "BHU CHAK NO. 70/15-L",
          lat: 30.251392,
          lng: 72.030999
        },
        {
          district: "Khanewal",
          label: "BHU CHAK NO. 92/10-R",
          lat: 30.41397,
          lng: 72.188062
        },
        {
          district: "Khanewal",
          label: "BHU CHAK NO.139/10-R",
          lat: 30.391273,
          lng: 72.19828
        },
        {
          district: "Khanewal",
          label: "BHU CHAK NO.9/V",
          lat: 30.282012,
          lng: 71.933999
        },
        {
          district: "Khanewal",
          label: "BHU JASSO KANWAN",
          lat: 30.335702,
          lng: 71.950363
        },
        {
          district: "Khanewal",
          label: "BHU SARDAR PUR",
          lat: 30.371853,
          lng: 72.121337
        },
        {
          district: "Khanewal",
          label: "BHU SHAM KOT",
          lat: 30.377124,
          lng: 71.911944
        },
        {
          district: "Khanewal",
          label: "DHQ Hospital Khanewal",
          lat: 30.29943,
          lng: 71.937214
        },
        {
          district: "Khanewal",
          label: "RHC KACHA KHUH",
          lat: 30.337733,
          lng: 71.778233
        },
        {
          district: "Khanewal",
          label: "RHC Makhdoom Pur, Khanewal, Khanewal",
          lat: 30.3998,
          lng: 72.2271
        },
        {
          district: "Khanewal",
          label: "THQ Hospital Channu",
          lat: 30.63099,
          lng: 71.888173
        },
        {
          district: "Khanewal",
          label: "THQ Hospital Jhanian",
          lat: 30.33706,
          lng: 71.93742
        },
        {
          district: "Khanewal",
          label: "THQ Hospital Kabir wala",
          lat: 30.690154,
          lng: 72.642735
        },
        {
          district: "Khanewal",
          label: "edo Office Kkhanewal",
          lat: 30.301079,
          lng: 71.936965
        },
        {
          district: "Khushab",
          label: "DHQ Khushab at Jahurabad",
          lat: 32.319833,
          lng: 71.545831
        },
        {
          district: "Khushab",
          label: "THQ HOSPITAL NOOR PUR THAL",
          lat: 32.27291,
          lng: 71.161015
        },
        {
          district: "Khushab",
          label: "THQ HOSPITAL QAIDABAD",
          lat: 32.302786,
          lng: 71.221238
        },
        {
          district: "Khushab",
          label: "edo Office Khushab",
          lat: 32.295612,
          lng: 71.527445
        },
        {
          district: "Lodhran",
          label: "BHU AIN WAHIN",
          lat: 29.728245,
          lng: 71.634285
        },
        {
          district: "Lodhran",
          label: "DHQ Hospital Lodhran",
          lat: 29.536697,
          lng: 71.630044
        },
        {
          district: "Lodhran",
          label: "RHC CHAK NO.231/WB",
          lat: 29.59369,
          lng: 71.47022
        },
        {
          district: "Lodhran",
          label: "RHC MEKHDOOM AALI",
          lat: 29.474876,
          lng: 71.810919
        },
        {
          district: "Lodhran",
          label: "THQ Hospital Dunya pur",
          lat: 29.914399,
          lng: 71.517336
        },
        {
          district: "Lodhran",
          label: "THQ Hospital Kahror Pacca",
          lat: 29.736763,
          lng: 71.960487
        },
        {
          district: "Lodhran",
          label: "edo Office Lodhran",
          lat: 29.540394,
          lng: 71.63862
        },
        {
          district: "Mandi Bahauddin",
          label: "#<Hospital:0x007f3e48f46860>",
          lat: null,
          lng: null
        },
        {
          district: "Mandi Bahauddin",
          label: "BHU BARMUSA",
          lat: 32.193675,
          lng: 73.646436
        },
        {
          district: "Mandi Bahauddin",
          label: "BHU BHAGOWAL",
          lat: 32.222644,
          lng: 73.520342
        },
        {
          district: "Mandi Bahauddin",
          label: "BHU KADHAR",
          lat: 32.37634,
          lng: 73.52757
        },
        {
          district: "Mandi Bahauddin",
          label: "BHU MANGAT",
          lat: 32.462315,
          lng: 73.55381
        },
        {
          district: "Mandi Bahauddin",
          label: "BHU NAIN RANJHA",
          lat: 32.320823,
          lng: 73.389148
        },
        {
          district: "Mandi Bahauddin",
          label: "DHQ Hospital Mandi Bahauddin",
          lat: 32.575074,
          lng: 73.492926
        },
        {
          district: "Mandi Bahauddin",
          label: "RHC Jokalian",
          lat: 32.508159,
          lng: 73.527286
        },
        {
          district: "Mandi Bahauddin",
          label: "RHC Malakwal",
          lat: 32.57006,
          lng: 73.3209
        },
        {
          district: "Mandi Bahauddin",
          label: "RHC Miana Gondal",
          lat: 32.610933,
          lng: 73.479566
        },
        {
          district: "Mandi Bahauddin",
          label: "RHC parhianwala",
          lat: 32.619119,
          lng: 73.44723
        },
        {
          district: "Mandi Bahauddin",
          label: "THQ Hospital Malakwal",
          lat: 32.579779,
          lng: 73.299709
        },
        {
          district: "Mandi Bahauddin",
          label: "THQ Hospital Phalia",
          lat: 32.554951,
          lng: 73.144186
        },
        {
          district: "Mianwali",
          label: "BHU ABBA KHEL",
          lat: 32.563420,
          lng: 71.362470
        },
        {
          district: "Mianwali",
          label: "DHQ Hospital Mianwali",
          lat: 32.577181,
          lng: 71.541581
        },
        {
          district: "Mianwali",
          label: "RHC Musa Khel, Mianwali",
          lat: 32.373974,
          lng: 71.290248
        },
        {
          district: "Mianwali",
          label: "THQ HOSPITAL ISA KHEL",
          lat: 32.808198,
          lng: 71.470362
        },
        {
          district: "Mianwali",
          label: "THQ Hospital Piplan",
          lat: 32.334398,
          lng: 71.373878
        },
        {
          district: "Mianwali",
          label: "THQ LEVEL HOSPITAL KALABAGH",
          lat: 32.960553,
          lng: 71.621181
        },
        {
          district: "Mianwali",
          label: "edo Office Mianwali",
          lat: 32.583333,
          lng: 71.550000
        },
        {
          district: "Multan",
          label: "Children Hospital, Multan",
          lat: 30.217498,
          lng: 71.445255
        },
        {
          district: "Multan",
          label: "Govt. Mushtaq Land THQ Hosp. Jalapur Pirwala",
          lat: 30.129186,
          lng: 71.692541
        },
        {
          district: "Multan",
          label: "Govt. THQ Hospital Shujabad",
          lat: 29.873241,
          lng: 71.294352
        },
        {
          district: "Multan",
          label: "Mian Muhammad Shahbaz Sharif General Hospital",
          lat: null,
          lng: null
        },
        {
          district: "Multan",
          label: "Nishtar Hospital, Nishtar Road, Multan",
          lat: null,
          lng: null
        },
        {
          district: "Muzaffargarh",
          label: "BHU 518-TDA",
          lat: null,
          lng: null
        },
        {
          district: "Muzaffargarh",
          label: "BHU 576-TDA",
          lat: null,
          lng: null
        },
        {
          district: "Muzaffargarh",
          label: "BHU Basti Chunjan",
          lat: null,
          lng: null
        },
        {
          district: "Muzaffargarh",
          label: "BHU Ghazi Ghatt",
          lat: null,
          lng: null
        },
        {
          district: "Muzaffargarh",
          label: "BHU Karam Dad Qureshi",
          lat: null,
          lng: null
        },
        {
          district: "Muzaffargarh",
          label: "BHU Kotla Gamoon",
          lat: null,
          lng: null
        },
        {
          district: "Muzaffargarh",
          label: "DHQ Hospital Muzaffargarh",
          lat: null,
          lng: null
        },
        {
          district: "Muzaffargarh",
          label: "RHC Chowck Sarwar Shaheed",
          lat: null,
          lng: null
        },
        {
          district: "Muzaffargarh",
          label: "RHC Gujrat",
          lat: null,
          lng: null
        },
        {
          district: "Muzaffargarh",
          label: "RHC Murad Abad",
          lat: null,
          lng: null
        },
        {
          district: "Muzaffargarh",
          label: "RHC Shah Jamal",
          lat: null,
          lng: null
        },
        {
          district: "Muzaffargarh",
          label: "RHC Sheher Sultan",
          lat: null,
          lng: null
        },
        {
          district: "Muzaffargarh",
          label: "THQ Hospital Alipur",
          lat: null,
          lng: null
        },
        {
          district: "Muzaffargarh",
          label: "THQ Hospital Kot Adu",
          lat: null,
          lng: null
        },
        {
          district: "Muzaffargarh",
          label: "THQ Jatoi",
          lat: null,
          lng: null
        },
        {
          district: "Muzaffargarh",
          label: "edo Office Muzaffargarh",
          lat: null,
          lng: null
        },
        {
          district: "Okara",
          label: "DHQ Hospital (South City) Okara",
          lat: 30.77,
          lng: 73.442315
        },
        {
          district: "Okara",
          label: "DHQ Hospital Okara",
          lat: 30.808258,
          lng: 73.450643
        },
        {
          district: "Okara",
          label: "RHC RENALA KHURD",
          lat: null,
          lng: null
        },
        {
          district: "Okara",
          label: "RHC SHAH BORE",
          lat: null,
          lng: null
        },
        {
          district: "Okara",
          label: "THQ HOSPITAL DEPALPUR",
          lat: null,
          lng: null
        },
        {
          district: "Okara",
          label: "THQ HOSPITAL HAVALI LAKHA",
          lat: null,
          lng: null
        },
        {
          district: "Okara",
          label: "edo Office Okara",
          lat: null,
          lng: null
        },
        {
          district: "Sheikhupura",
          label: "DHQ Teaching Hospital, Sheikhupura",
          lat: null,
          lng: null
        },
        {
          district: "Sheikhupura",
          label: "RHC Khanqah Dogran",
          lat: null,
          lng: null
        },
        {
          district: "Sheikhupura",
          label: "RHC SHARAQ PUR SHARIF",
          lat: null,
          lng: null
        },
        {
          district: "Sheikhupura",
          label: "THQ Hospital Ferozewala",
          lat: null,
          lng: null
        },
        {
          district: "Sheikhupura",
          label: "THQ Hospital Muridke",
          lat: null,
          lng: null
        },
        {
          district: "Sheikhupura",
          label: "THQ Hospital Safdarabad",
          lat: null,
          lng: null
        },
        {
          district: "Sheikhupura",
          label: "THQ Hospital Sharaqpur Sharif",
          lat: null,
          lng: null
        },
        {
          district: "Sialkot",
          label: "Allama Iqbal Memorial Hospital, Sialkot",
          lat: null,
          lng: null
        },
        {
          district: "Sialkot",
          label: "Civil Hospital Daska",
          lat: null,
          lng: null
        },
        {
          district: "Sialkot",
          label: "Government Sardar Begum Teaching Hospital, Sialkot",
          lat: null,
          lng: null
        },
        {
          district: "Sialkot",
          label: "THQ Hospital Pasrur",
          lat: null,
          lng: null
        },
        {
          district: "Sialkot",
          label: "THQ Kotli Loharan",
          lat: null,
          lng: null
        },
        {
          district: "Sialkot",
          label: "THQ Sambrial",
          lat: null,
          lng: null
        },
        {
          district: "Toba Tek Singh",
          label: "DHQ Hospital Toba Tek Singh",
          lat: 30.974103,
          lng: 72.490343
        },
        {
          district: "Toba Tek Singh",
          label: "Govt. Eye-cum-General Hospital Gojra",
          lat: null,
          lng: null
        },
        {
          district: "Toba Tek Singh",
          label: "THQ Hospital Kamalia",
          lat: null,
          lng: null
        },
        {
          district: "Vehari",
          label: "BHU 1/WB",
          lat: null,
          lng: null
        },
        {
          district: "Vehari",
          label: "BHU 122/WB",
          lat: null,
          lng: null
        },
        {
          district: "Vehari",
          label: "BHU 155/WB",
          lat: null,
          lng: null
        },
        {
          district: "Vehari",
          label: "BHU 158/WB",
          lat: null,
          lng: null
        },
        {
          district: "Vehari",
          label: "BHU 187/WB",
          lat: null,
          lng: null
        },
        {
          district: "Vehari",
          label: "BHU 22/WB",
          lat: null,
          lng: null
        },
        {
          district: "Vehari",
          label: "BHU 44/kb",
          lat: null,
          lng: null
        },
        {
          district: "Vehari",
          label: "BHU 477/EB",
          lat: null,
          lng: null
        },
        {
          district: "Vehari",
          label: "BHU 5/WB",
          lat: null,
          lng: null
        },
        {
          district: "Vehari",
          label: "BHU 535/EB",
          lat: null,
          lng: null
        },
        {
          district: "Vehari",
          label: "BHU 569/EB",
          lat: null,
          lng: null
        },
        {
          district: "Vehari",
          label: "BHU 69/WB",
          lat: null,
          lng: null
        },
        {
          district: "Vehari",
          label: "BHU 88/WB",
          lat: null,
          lng: null
        },
        {
          district: "Vehari",
          label: "BHU FATEH PUR",
          lat: null,
          lng: null
        },
        {
          district: "Vehari",
          label: "BHU QADAR ABAD",
          lat: null,
          lng: null
        },
        {
          district: "Vehari",
          label: "BHU SAHAR",
          lat: null,
          lng: null
        },
        {
          district: "Vehari",
          label: "BHU SARGANA",
          lat: null,
          lng: null
        },
        {
          district: "Vehari",
          label: "DHQ Hospital Vehari",
          lat: null,
          lng: null
        },
        {
          district: "Vehari",
          label: "RHC 188/EB MIAN PAKHI",
          lat: null,
          lng: null
        },
        {
          district: "Vehari",
          label: "RHC Garah More",
          lat: null,
          lng: null
        },
        {
          district: "Vehari",
          label: "RHC TIBBA SULTAN PUR",
          lat: null,
          lng: null
        },
        {
          district: "Vehari",
          label: "THQ Burewala",
          lat: null,
          lng: null
        },
        {
          district: "Vehari",
          label: "THQ Mailsi",
          lat: null,
          lng: null
        }
]

export const optionsDay = [
  { label: "1st", value: '01' },
  { label: "2nd", value: '02' },
  { label: "3rd", value: '03' },
  { label: "4th", value: '04' },
  { label: "5th", value: '05' },
  { label: "6th", value: '06' },
  { label: "7th", value: '07' },
  { label: "8th", value: '08' },
  { label: "9th", value: '09' },
  { label: "10th", value: '10' },
  { label: "11th", value: '11' },
  { label: "12th", value: '12' },
  { label: "13th", value: '13' },
  { label: "14th", value: '14' },
  { label: "15th", value: '15' },
  { label: "16th", value: '16' },
  { label: "17th", value: '17' },
  { label: "18th", value: '18' },
  { label: "19th", value: '19' },
  { label: "20th", value: '20' },
  { label: "21st", value: '21' },
  { label: "22th", value: '22' },
  { label: "23rd", value: '23' },
  { label: "24th", value: '24' },
  { label: "25th", value: '25' },
  { label: "26th", value: '26' },
  { label: "27th", value: '27' },
  { label: "28th", value: '28' },
  { label: "29th", value: '29' },
  { label: "30th", value: '30' },
  { label: "31st", value: '31' },
];

export const options = [
  { label: "January", value: '01' },
  { label: "February", value: '02' },
  { label: "March", value: '03' },
  { label: "April", value: '04' },
  { label: "May", value: '05' },
  { label: "June", value: '06' },
  { label: "July", value: '07' },
  { label: "August", value: '08' },
  { label: "September", value: '09' },
  { label: "October", value: '10' },
  { label: "November", value: '11' },
  { label: "December", value: '12' },
];

export const ageGroups = [
  { label: "0 - 12", value: 'child' },
  { label: "13 - 40", value: 'adult' },
  { label: "41+", value: 'senior' },
];

export const monthList = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

export const optionsYear = [
    { label: "2021", value: '2021' },
    { label: "2022", value: '2022' },
    { label: "2023", value: '2023' },
];

export const optionsDHIS2Punjab = [
  { label: "Dengue Fever", value: 'aM3Na2jQTts' },
  { label: "Chikungunya", value: 'HWGyRvqrUEi' },
  { label: "Scabies", value: 'hvsWLHwNqSJ' },
  { label: "Typhoid", value: 'Af99m9CJdpH' },
  { label: "Dysentry", value: 'jdogBqT66LU' },
  { label: "Dog Bites", value: 'UhColMlS7sk' },
  { label: "Tuberculosis", value: 'nvmd6bKU7Mj' },
  { label: "Malaria", value: 'AdIWS3Qd4Lx' },
  { label: "Leishmaniasis", value: 'EU6zq5FygUi' },
  { label: "CCHF", value: 'E2MQ8ypL562' },
];

export const optionsIDSR = [
  { label: "Dengue Fever", value: 'aM3Na2jQTts' },
  { label: "Chikungunya", value: 'HWGyRvqrUEi' },
  { label: "Scabies", value: 'hvsWLHwNqSJ' },
  { label: "Typhoid", value: 'Af99m9CJdpH' },
  { label: "Dysentry", value: 'jdogBqT66LU' },
  { label: "Dog Bites", value: 'UhColMlS7sk' },
  { label: "Tuberculosis", value: 'nvmd6bKU7Mj' },
  { label: "Malaria", value: 'AdIWS3Qd4Lx' },
  { label: "Leishmaniasis", value: 'EU6zq5FygUi' },
  { label: "CCHF", value: 'E2MQ8ypL562' },
];

export const optionsDSS = [
  { label: "Dengue Fever", value: "DENGUE FEVER (SUSPECTED)" },
  // { label: "Chikungunya", value: 'HWGyRvqrUEi' },
  { label: "Scabies", value: 'SCABIES' },
  // { label: "Typhoid", value: 'Af99m9CJdpH' },
  // { label: "Dysentry", value: 'jdogBqT66LU' },
  { label: "Dog Bites", value: 'DOG BITE' },
  { label: "Tuberculosis", value: 'TUBERCULOSIS (SUSPECTED)' },
  // { label: "Malaria", value: 'AdIWS3Qd4Lx' },
  // { label: "Leishmaniasis", value: 'EU6zq5FygUi' },
  // { label: "CCHF", value: 'E2MQ8ypL562' },
];

export const genders = [
  { label: "male", value: 'male' },
  { label: "female", value: 'female' },
];

export const MapLatLng = [
  { id: 1, label: "Rahimyar Khan", lat: 28.420240 , lng:70.295181, count:0, disease:'default'},
  { id: 2, label: "Chakwal" ,lat: 32.932789 , lng:72.862961, count:0, disease:'default'},
  { id: 3, label: "Lodhran" ,lat: 29.536751 , lng:71.630470, count:0, disease:'default'},
  { id: 4, label: "Sargodha", lat: 32.085369 , lng: 72.674980, count:0, disease:'default'},
  { id: 5, label: "Mandi Bahauddin", lat: 32.5846351 , lng:73.4914169, count:0, disease:'default'},
  { id: 6, label: "Bahawalpur", lat: 29.394644 , lng:71.6638747, count:0, disease:'default'},
  { id: 7, label: "Faisalabad", lat: 31.4220558 , lng:73.0923253, count:0, disease:'default'},
  { id: 8, label: "Multan", lat: 30.197838 , lng:71.4719683, count:0, disease:'default'},
  { id: 9, label: "Sahiwal", lat: 30.6715098 , lng:73.1110572, count:0, disease:'default'},
  { id: 10, label: "Sheikhupura", lat: 31.7085285 , lng:73.9864014, count:0, disease:'default'},
  { id: 11, label: "Sialkot", lat: 32.4935378 , lng:74.5411575, count:0, disease:'default'},
  { id: 12, label: "Attock", lat: 33.7695323 , lng:72.3609, count:0, disease:'default'},
  { id: 13, label: "Khanewal", lat: 30.3022528 , lng:71.9278916, count:0, disease:'default'},
  { id: 14, label: "Jhelum", lat: 32.9247256 , lng:73.719388, count:0, disease:'default'},
  { id: 15, label: "Toba Tek Singh", lat: 30.9777056 , lng:72.4860799, count:0, disease:'default'},
  { id: 16, label: "Kasur", lat: 31.1175143 , lng:74.4478314, count:0, disease:'default'},
  { id: 17, label: "Nankana Sahib", lat: 31.4548712 , lng:73.7090967, count:0, disease:'default'},
  { id: 18, label: "Gujranwala", lat: 32.1525312 , lng:74.1933745, count:0, disease:'default'},
  { id: 19, label: "Rawalpindi", lat: 33.5914237 , lng:73.0535122, count:0, disease:'default'},
  { id: 20, label: "Hafizabad", lat:32.072546 , lng:73.6857558, count:0, disease:'default'},
  { id: 21, label: "Vehari", lat: 30 , lng:72.333333, count:0, disease:'default'},
  { id: 22, label: "Muzaffargarh", lat: 30.0778083 , lng:71.1888419, count:0, disease:'default'},
  { id: 23, label: "Pakpattan", lat: 30.3422503 , lng:73.3891875, count:0, disease:'default'},
  { id: 24, label: "Okara", lat: 30.8091281 , lng:73.4493301, count:0, disease:'default'},
  { id: 25, label: "Khushab", lat: 32.2903666 , lng:72.3515792, count:0, disease:'default'},
  { id: 26, label: "Lahore", lat: 31.5656822 , lng:74.3141829, count:0, disease:'default'},
  { id: 27, label: "Gujrat", lat: 32.5686782 , lng:74.071481, count:0, disease:'default'},
  { id: 28, label: "Jhang", lat: 31.2728805 , lng:72.3103071, count:0, disease:'default'},
  { id: 29, label: "Narowal", lat: 32.1009752 , lng:74.872238, count:0, disease:'default'},
  { id: 30, label: "Mianwali", lat: 32.5705533 , lng:71.5239763, count:0, disease:'default'},
  { id: 31, label: "Bahawalnagar", lat: 29.9935922 , lng:73.2527621, count:0, disease:'default'},
];

export const areaPrimaryXAxis = {
  valueType: 'DateTime',
  labelFormat: 'y',
  majorGridLines: { width: 0 },
  intervalType: 'Years',
  edgeLabelPlacement: 'Shift',
  labelStyle: { color: 'gray' },
};

export const areaPrimaryYAxis = {
  labelFormat: '{value}%',
  lineStyle: { width: 0 },
  maximum: 4,
  interval: 1,
  majorTickLines: { width: 0 },
  minorTickLines: { width: 0 },
  labelStyle: { color: 'gray' },

};

export const barPrimaryXAxis = {
  valueType: 'Category',
  interval: 1,
  majorGridLines: { width: 0 },
};

export const barPrimaryYAxis = {
  majorGridLines: { width: 0 },
  majorTickLines: { width: 0 },
  lineStyle: { width: 0 },
  labelStyle: { color: 'transparent' },
};

const areaChartData = [
  [
    { x: new Date(2016, 0, 1), y: 2.2 },
    { x: new Date(2017, 0, 1), y: 3.4 },
    { x: new Date(2018, 0, 1), y: 2.8 },
    { x: new Date(2019, 0, 1), y: 1.6 },
    { x: new Date(2020, 0, 1), y: 2.3 },
    { x: new Date(2021, 0, 1), y: 2.5 },
    { x: new Date(2022, 0, 1), y: 2.9 },
  ],
  [
    { x: new Date(2016, 0, 1), y: 2 },
    { x: new Date(2017, 0, 1), y: 1.7 },
    { x: new Date(2018, 0, 1), y: 1.8 },
    { x: new Date(2019, 0, 1), y: 2.1 },
    { x: new Date(2020, 0, 1), y: 2.3 },
    { x: new Date(2021, 0, 1), y: 1.7 },
    { x: new Date(2022, 0, 1), y: 1.5 },
  ],
  [
    { x: new Date(2016, 0, 1), y: 0.8 },
    { x: new Date(2017, 0, 1), y: 1.3 },
    { x: new Date(2018, 0, 1), y: 1.1 },
    { x: new Date(2019, 0, 1), y: 1.6 },
    { x: new Date(2020, 0, 1), y: 2 },
    { x: new Date(2021, 0, 1), y: 1.7 },
    { x: new Date(2022, 0, 1), y: 2.3 },  
  ]
];

export const areaCustomSeries = [
  {
    dataSource: areaChartData[0],
    xName: 'x',
    yName: 'y',
    name: 'COVID-19',
    opacity: '0.8',
    type: 'SplineArea',
    width: '2',

  },
  {
    dataSource: areaChartData[1],
    xName: 'x',
    yName: 'y',
    name: 'CCHF',
    opacity: '0.8',
    type: 'SplineArea',
    width: '2',
  },
  {
    dataSource: areaChartData[2],
    xName: 'x',
    yName: 'y',
    name: 'Chikungunya',
    opacity: '0.8',
    type: 'SplineArea',
    width: '2',
  },
];

export const barChartData = [
  [
    { x: 'Covid', y: 46 },
    { x: 'CCHF', y: 27 },
    { x: 'Anthrax', y: 26 },
  ],
  [
    { x: 'Covid', y: 37 },
    { x: 'CCHF', y: 23 },
    { x: 'Anthrax', y: 18 },
  ],
  [
    { x: 'Covid', y: 38 },
    { x: 'CCHF', y: 17 },
    { x: 'Anthrax', y: 26 },
  ],
];

export const barCustomSeries = [
  {
    dataSource: barChartData[0],
    xName: 'x',
    yName: 'y',
    name: 'Covid-19',
    type: 'Column',
    marker: {
      dataLabel: {
        visible: true,
        position: 'Top',
        font: { fontWeight: '600', color: '#ffffff' },
      },
    },
  },
  {
    dataSource: barChartData[1],
    xName: 'x',
    yName: 'y',
    name: 'CCHF',
    type: 'Column',
    marker: {
      dataLabel: {
        visible: true,
        position: 'Top',
        font: { fontWeight: '600', color: '#ffffff' },
      },
    },
  },
  {
    dataSource: barChartData[2],
    xName: 'x',
    yName: 'y',
    name: 'Anthrax',
    type: 'Column',
    marker: {
      dataLabel: {
        visible: true,
        position: 'Top',
        font: { fontWeight: '600', color: '#ffffff' },
      },
    },
  },
];

export const colorMappingData = [
  [
    { x: 'Jan', y: 6.96 },
    { x: 'Feb', y: 8.9 },
    { x: 'Mar', y: 12 },
    { x: 'Apr', y: 17.5 },
    { x: 'May', y: 22.1 },
    { x: 'June', y: 25 },
    { x: 'July', y: 29.4 },
    { x: 'Aug', y: 29.6 },
    { x: 'Sep', y: 25.8 },
    { x: 'Oct', y: 21.1 },
    { x: 'Nov', y: 15.5 },
    { x: 'Dec', y: 9.9 },
  ],
  ['#FFFF99'],
  ['#FFA500'],
  ['#FF4040'],
];

export const rangeColorMapping = [
  { label: '1°C to 10°C',
    start: '1',
    end: '10',
    colors: colorMappingData[1] },

  { label: '11°C to 20°C',
    start: '11',
    end: '20',
    colors: colorMappingData[2] },

  { label: '21°C to 30°C',
    start: '21',
    end: '30',
    colors: colorMappingData[3] },

];

export const ColorMappingPrimaryXAxis = {
  valueType: 'Category',
  majorGridLines: { width: 0 },
  title: 'Months',
};

export const ColorMappingPrimaryYAxis = {
  lineStyle: { width: 0 },
  majorTickLines: { width: 0 },
  minorTickLines: { width: 0 },
  labelFormat: '{value}°C',
  title: 'Temperature',
};

export const FinancialPrimaryXAxis = {
  valueType: 'DateTime',
  minimum: new Date('2016, 12, 31'),
  maximum: new Date('2017, 9, 30'),
  crosshairTooltip: { enable: true },
  majorGridLines: { width: 0 },
};

export const FinancialPrimaryYAxis = {
  title: 'Price',
  minimum: 100,
  maximum: 180,
  interval: 20,
  lineStyle: { width: 0 },
  majorTickLines: { width: 0 },
};

export const LinePrimaryXAxis = {
  valueType: 'DateTime',
  labelFormat: 'y',
  intervalType: 'Months',
  edgeLabelPlacement: 'Shift',
  majorGridLines: { width: 0 },
  background: 'white',
};

export const LinePrimaryYAxis = {
  labelFormat: '{value}',
  rangePadding: 'None',
  minimum: 0,
  maximum: 250,
  interval: 50,
  lineStyle: { width: 0 },
  majorTickLines: { width: 0 },
  minorTickLines: { width: 0 },
};

export const LinePriorityXAxis = {
  valueType: 'DateTime',
  labelFormat: 'y',
  intervalType: 'Months',
  edgeLabelPlacement: 'Shift',
  majorGridLines: { width: 0 },
  background: 'white',
};

export const LinePriorityYAxis = {
  labelFormat: '{value}',
  rangePadding: 'None',
  minimum: 0,
  maximum: 8200,
  interval: 1000,
  lineStyle: { width: 0 },
  majorTickLines: { width: 0 },
  minorTickLines: { width: 0 },
};

export const DiseasesXAxis = {
  valueType:'Disease',
  labelFormat: 'y',
  intervalType:'Diseases',
  majorGridLines:{width:0},
  background:'white',
};

export const DiseasesYAxis = {
  labelFormat: '{value}',
  rangePadding: 'None',
  minimum: 0,
  maximum: 250,
  interval: 50,
  lineStyle: { width: 0 },
  majorTickLines: { width: 0 },
  minorTickLines: { width: 0 },
};

export const links = [
  {
    title: 'Dashboard',
    links: [
      {
        name: 'Home',
        to: 'Home',
        icon: <MdHome className='flex align-middle h-7 justify-center rounded-lg w-auto dark:text-black bg-white'/>,
        // icon: <img className='flex align-middle justify-center rounded-xl h-20 ml-2 mt-4 w-auto bg-white dark:bg-secondary-dark-bg' src={Home}/> ,
      },
    ],
  },  
  {
    title: 'Data Sources',
    links: [            
      {
        name: 'DHIS 2 - Punjab',
        to: 'DHIS2Punjab',
        // icon: <MdHome />,
        icon: <img className='flex align-middle justify-center rounded-lg h-7 w-auto bg-white' src={dhis2Logo}/> ,
      },
      {
      name: 'IDSR',
      to: 'IDSR',
      // icon: <MdHome />,
      icon: <img className='flex align-middle justify-center rounded-lg h-7 w-auto bg-white' src={nihLogo}/> ,
      },
      // {
        // name: 'DHIS 1 - Punjab',
        // to: 'DHISPunjab',
        // icon: <MdHome />,
        // icon: <img className='flex align-middle justify-center rounded-xl h-5 w-auto bg-white dark:bg-secondary-dark-bg' src={dhisLogo}/> ,
      // },

      {
        name: 'DSS Punjab',
        to: 'DSSPunjab',
        icon: <img className='flex align-middle justify-center rounded-lg h-7 w-auto bg-white' src={gopGreen}/> ,
      },
      {
        name: 'ADRS Punjab',
        to: 'ADRSPunjab',
        // icon: <MdHome />,
        
        icon: <img className='flex align-middle justify-center rounded-lg h-7 w-auto bg-white' src={adrsLogo}/> ,
      },
      // {
      //   name: 'COVID 19',
      //   to: 'Covid19',
      //   // icon: <MdHome />,
      //   icon: <img className='flex align-middle justify-center rounded-xl h-5 w-auto bg-white dark:bg-secondary-dark-bg' src={covidLogo}/> ,
      // },
      // {
      //   name: 'Dengue Punjab',
      //   to: 'Dengue',
      //   // icon: <MdHome />,
      //   icon: <img className='flex align-middle justify-center rounded-xl h-5 w-auto bg-white dark:bg-secondary-dark-bg' src={dengueLogo}/> ,
      // },
      // {
      //   name: 'MIS Dashboard',
      //   to: 'MIS',
      //   icon: <img className='flex align-middle justify-center rounded-xl h-5 w-auto bg-white dark:bg-secondary-dark-bg' src={gopGrey}/> ,
      // },
    ],
  },
  {
      title: 'Analysis',
    links: [
      {
        name: 'Analyse Data',
        to: 'analysis',
        icon: <MdDataUsage />,
      },
    ]
  },
  // {
  //   title: 'Diseases',
  //   links: [
  //     {
  //       name: 'Zoonotic Diseases',
  //       to: 'Zoonotic',
  //       icon: <MdDataUsage />,
  //     },
  //     {
  //       name: 'Vector Borne Diseases',
  //       to: 'VectorBorne',
  //       icon: <MdDataUsage />,
  //     },
  //     {
  //       name: 'Water Borne Diseases',
  //       to: 'Waterborne',
  //       icon: <MdDataUsage />,
  //     },
  //     {
  //       name: 'Pandemic Diseases',
  //       to:'Pandemic',
  //       icon: <MdDataUsage />,
  //     },
  //   ],
  // },
  {
    title: 'Documentations',
    links: [
      {
        name: 'Geo Tags / Locations',
        to: 'GeoTags',
        // icon: <MdReport />,
        icon: <img className='flex align-middle justify-center rounded-lg h-7 w-auto bg-white' src={hospitalLogo}/> ,
      },
      {
        name: 'Important Links',
        to:'DataSources',
        // icon: <MdReport />,
        icon: <img className='flex align-middle justify-center rounded-lg h-7 w-auto bg-white' src={documentLogo}/> ,
      },
      {
        name: 'Definitions',
        to: 'Definitions',
        // icon: <MdReport />,
        icon: <img className='flex align-middle justify-center rounded-lg h-7 w-auto bg-white' src={publicationLogo}/> ,
      },
      {
        name: 'Geo Representation',
        to:'Maps',
        // icon:<MdReport/>,
        icon: <img className='flex align-middle justify-center rounded-lg h-7 w-auto bg-white' src={locationLogo}/> ,
      }
    ],
  },
];

export const chatData = [
  {
    image:source,
    message: 'New Covid-19 case found in Attock',
    desc: 'Email concerned departments',
    time: '9:08 AM',
  },
  {
    image:source,
    message: 'Malaria cases rapidly increases in rural areas',
    desc: 'Email concerned departments',
    time: '11:56 AM',
  },
  {
    image:source,
    message: 'Meeting with Clients',
    desc: 'Set a schedule',
    time: '4:39 AM',
  },
];

export const priorityOHDiseases = [
  {
    icon: <img className='flex p-2 align-middle justify-center rounded-xl h-10 w-auto bg-blue-100 dark:bg-white' src={covidBlack} />,
    amount: '+1',
    title: 'COVID-19',
    desc: 'Attock',
    iconColor: '#03C9D7',
    iconBg: '#E5FAFB',
    pcColor: 'green-600',
    to:'/pandemic/coronas',
    dataSources:'1 Data Source'
  },
  {
    icon: <img className='flex p-2 align-middle justify-center rounded-xl h-10 w-auto bg-blue-200 dark:bg-white' src={dengueBlack} />,
    amount: '+3',
    desc: 'All Over Punjab',
    title: 'Dengue',
    iconColor: 'rgb(0, 194, 146)',
    iconBg: 'rgb(235, 250, 242)',
    pcColor: 'red-600',
    to:'/vectorborne/dengues',
    dataSources:'2 Data Sources'
  },
  {
    icon: <img className='flex p-2 align-middle justify-center rounded-xl h-10 w-auto bg-blue-300 dark:bg-white' src={dogbiteBlack} />,
    amount: '+46',
    title: 'Dog Bites',
    desc: 'All Over Punjab',
    iconColor: 'rgb(228, 106, 118)',
    iconBg: '#CED2C2',
    pcColor: 'green-600',
    to:'/zoonotic/dogbites',
    dataSources:'2 Data Sources'
  },
  {
    icon: <img className='flex p-2 align-middle justify-center rounded-xl h-10 w-auto bg-blue-200 dark:bg-white' src={covidBlack} />,
    amount: '+0',
    title: 'Anthrax',
    desc: 'All Over Punjab',
    iconColor: 'rgb(228, 106, 200)',
    iconBg: '#BFD1DF',
    pcColor: 'green-600',
    to:'/zoonotic/anthraxes',
    dataSources:'1 Data Source'
  },
  {
    icon: <img className='flex p-2 align-middle justify-center rounded-xl h-10 w-auto bg-blue-100 dark:bg-white' src={cchfBlack} />,
    amount: '+0',
    title: 'CCHF',
    desc: 'All Over Punjab',
    iconColor: 'rgb(228, 106, 220)',
    iconBg: '#F1C5AE',
    pcColor: 'Blue-600',
    to:'/vectorborne/cchfs',
    dataSources:'0 DataSources'
  },
  {
    icon: <img className='flex p-2 align-middle justify-center rounded-xl h-10 w-auto bg-blue-200 dark:bg-white' src={covidBlack} />,
    amount: '+0',
    title: 'Salmonelosis',
    desc: 'All Over Punjab',
    iconColor: 'rgb(228, 106, 240)',
    iconBg: '#ECDDD0',
    pcColor: 'Red-600',
    to:'/zoonotic/salmonelloses',
    dataSources:'0 Data Source'
  },
];

export const otherOHDiseases = [
  {
    icon: <img className='flex p-2 align-middle justify-center rounded-xl h-10 w-auto bg-blue-100 dark:bg-white' src={dengueBlack} />,
    amount: '+19',
    title: 'Leishmaniasis',
    desc: 'All over Punjab',
    iconBg: '#ECDDD0',
    pcColor: 'red-600',
    to:'/vectorborne/leishmaniases',
    dataSources:'1 Data Source'
  },
  {
    icon: <img className='flex p-2 align-middle justify-center rounded-xl h-10 w-auto bg-blue-200 dark:bg-white' src={malariaBlack} />,
    amount: '+1',
    title: 'Malaria',
    desc: 'All over Punjab',
    iconBg: '#F1C5AE',
    pcColor: 'red-600',
    to:'/vectorborne/malarias',
    dataSources:'2 Data Sources'
  },
  {
    icon: <img className='flex p-2 align-middle justify-center rounded-xl h-10 w-auto bg-blue-300 dark:bg-white' src={covidBlack} />,
    amount: '+0',
    title: 'Avian Influenza',
    desc: 'All over Punjab',
    iconBg: '#00C292',
    pcColor: 'green-600',
    to:'/pandemic/avianinfluenzas',
    dataSources:'0 Data Source'
  },
  {
    icon: <img className='flex p-2 align-middle justify-center rounded-xl h-10 w-auto bg-blue-200 dark:bg-white' src={covidBlack} />,
    amount: '+0',
    title: 'Chikungunya',
    desc: 'All over Punjab',
    iconBg: '#BFD1DF',
    pcColor: 'green-600',
    to:'/vectorborne/chikungunyas',
    dataSources:'1 Data Source'
  },
  {
    icon: <img className='flex p-2 align-middle justify-center rounded-xl h-10 w-auto bg-blue-100 dark:bg-white' src={tbBlack} />,
    amount: '+0',
    title: 'Suspected TB',
    desc: 'All over Punjab',
    iconBg: '#00C722',
    pcColor: 'green-600',
    to:'/zoonotic/bovinetuberculoses',
    dataSources:'0 Data Source'
  },
  {
    icon: <img className='flex p-2 align-middle justify-center rounded-xl h-10 w-auto bg-blue-200 dark:bg-white' src={covidBlack} />,
    amount: '+0',
    title: 'Brucellosis',
    desc: 'All over Punjab',
    iconBg: '#00C252',
    pcColor: 'green-600',
    to:'/zoonotic/brucelloses',
    dataSources:'0 Data Source'
  },
  {
    icon: <img className='flex p-2 align-middle justify-center rounded-xl h-10 w-auto bg-blue-200 dark:bg-white' src={amebiasisBlack} />,
    amount: '+0',
    title: 'Amebiasis',
    desc: 'All over Punjab',
    iconBg: '#E5FAFB',
    pcColor: 'Blue-600',
    to:'/waterborne/amebiases',
    dataSources:'1 Data Source'
  },
];

export const dropdownList = [
  {
    name: 'Zoonotic',
    link:"January",
    no: '1',
  },
    {
    name: 'Vector Borne',
    no: '2',
  },
  {
    name: 'Water Borne',
    no: '3',
  },
  {
    name: 'Pandemic',
    no: '4',
  },
  {
    name: 'Zoonotic',
    link:"January",
    no: '1',
  },
  {
    name: 'Zoonotic',
    link:"January",
    no: '1',
  },  {
    name: 'Zoonotic',
    link:"January",
    no: '1',
  },  {
    name: 'Zoonotic',
    link:"January",
    no: '1',
  },  {
    name: 'Zoonotic',
    link:"January",
    no: '1',
  },
];

export const themeColors = [
  {
    name: 'blue-theme',
    color: '#1A97F5',
  },
  {
    name: 'teal-theme',
    color: '#0D9488',
  },
  {
    name: 'green-theme',
    color: '#03C9D7',
  },
  {
    name: 'purple-theme',
    color: '#7352FF',
  },
  {
    name: 'red-theme',
    color: '#FF5C8E',
  },
  {
    name: 'indigo-theme',
    color: '#1E4DB7',
  },
  {
    color: '#FB9678',
    name: 'orange-theme',
  },
  {
    color: 'green',
    name: 'green-theme',
  },
];

export const userProfileData = [
  {
    icon: <BsCurrencyDollar />,
    title: 'My Profile',
    desc: 'Account Settings',
    iconColor: '#03C9D7',
    iconBg: '#E5FAFB',
  },
  {
    icon: <BsShield />,
    title: 'My Inbox',
    desc: 'Messages & Emails',
    iconColor: 'rgb(0, 194, 146)',
    iconBg: 'rgb(235, 250, 242)',
  },
  {
    icon: <FiCreditCard />,
    title: 'My Tasks',
    desc: 'To-do and Daily Tasks',
    iconColor: 'rgb(255, 244, 229)',
    iconBg: 'rgb(254, 201, 15)',
  },
];

export const definitionsGrid = [
  {
    field: 'No',
    headerText: 'No.',
    textAlign: 'Left',
    width: '30',
  },
  {
    field: 'Disease',
    headerText: 'Disease Name',
    width: '60',
    textAlign: 'Left',
  },
  {
    field: 'Definition',
    headerText: 'Definition',
    width: '400',
    textAlign: 'Left',
  },
  {
    field: 'Threshold',
    headerText: 'Threshold',
    width: '80',
    textAlign: 'Left',
  },
]

export const definitionsData = [
  {
    No:"1",
    Disease: 'Avian/Seasonal Influenza',
    Definition:"An acute respiratory infection with measured fever of 238°C with cough and with onset within the last 10 days",
    Threshold:"3+ cases in one location",
  },
  {
    No:"2",
    Disease: 'Suspected Corona Virus',
    Definition:"Acute onset of any three or more of the following signs or symptoms like fever, dry cough, general weakness/fatigue, headache, myalgia, sore throat, coryza, dyspnea, anorexia/nausea/vomiting and diarrhea or exposure to the k own positive case/residing in high transmission area within 14 days of the onset symptoms",
    Threshold:"1 suspected case",
  },
  {
    No:"3",
    Disease: 'Suspected Viral Hemorrhagic Fever (CCHF)',
    Definition:"Acute onset of illness with high grade fever (38.5° C) for > 3 days and < 10 days will any two of the following: hemorhagic or purpurio rash. nose bleed, blood in vomit sputum/ stool, other hemorrhagic symptoms; and no known predisposing factors for hemorrhagic manifestations among those in contact with a confirmed patient of handling animals and raw animal products and when fever does not respond to antibiotics or anti-malarial treatment",
    Threshold:"1 Suspected case",
  },
  {
    No:"4",
    Disease: 'Chikungunya',
    Definition:"Any person with acute onset fever >38.5c and severe arthralgia, myalgia not explained by other medical conditions",
    Threshold:"3+ cases in one location",
  },
  {
    No:"5",
    Disease: 'Suspected Dengue Fever',
    Definition:"An acute febrile illness of 2-7 days duration with 2 or more of the following; headache, retro-orbital pain, myalgia (muscle pain) or arthralgia (joint pain)",
    Threshold:"Cluster of 3 Suspected cases",
  },
  {
    No:"6",
    Disease: 'Cutaneous Leishmaniasis',
    Definition:"Appearance of one or more lesions, typically on uncovered parts of the body; the face neck, arms and legs, which begins as nodules and turn into skin ulcers eventually healing but leaving a depressed scar.",
    Threshold:"1 Suspected case in non-endemic areas and a cluster of 3 cases in endemic areas",
  },
  {
    No:"7",
    Disease: 'Suspected Malaria',
    Definition:"Any person with fever or history of fever within the past 48 hours (with or without other symptoms such as nausea, vomiting and diarrhea, headache, back pain, chills, myalgia) in whom other obvious causes of fever have been excluded.",
    Threshold:"Case count more than 2 times mean number of cases of the previous weeks for a reporting unit",
  },
  {
    No:"8",
    Disease: 'Anthrax',
    Definition:"Any case that is compatible with the clinical description of cutaneous, pulmonary, gastrointestinal, injectional or meningeal Anthrax and had epidemiological link to confirm or suspected animal case or contaminated animal products.",
    Threshold:"1 case",
  },
  {
    No:"9",
    Disease: 'Suspected TB',
    Definition:"A patient who represents with symptoms or signs suggestive of TB as: A. Patient with cough of 3 weeks or longer, OR B. Cough less than 3 weeks with symptoms: o Sputum (Blood stained or without blood) o Fever followed by sweating usually at night o Loss of weight and appetite o A History of previous TB in a parent or TB history in family or close contact",
    Threshold:"1 case or clinically Suspected",
  },
  {
    No:"10",
    Disease: 'Brucellosis',
    Definition:"A case with acute or insidious onset fever, night sweats, undue fatigue anorexia, weight loss, head ache, and joint pain and is epidemiological linked to suspected or confirmed animal cases or contaminated food of animal origin.",
    Threshold:"-",
  },
  {
    No:"11",
    Disease: 'Dog Bite/Rabies',
    Definition:"Any case with acute onset of neurologiealayridrome (encephalitis) dominaled by any or more of the following symptoma; excitability, aerophobin, paralysis, hydrophobia delirium convulsions, or hyperactivity (furious rabies) or paralytic syndromes (dumb rabies) that progresses towards respiratory failure, coma and death with history of bites or scratches or contact with saliva from a suspected animal during last 30 to 90 days,",
    Threshold:"1 Suspected case in a susceptible animal species and/or human",
  },
  {
    No:"12",
    Disease: 'Suspected Typhoid/ Salmonellosis',
    Definition:"Any person with acute febrile illness fever of at least 38°€ for 3 or more days with abdominal discomfort, fatigue and diarrhea/ constipation.",
    Threshold:"3+ Suspected cases",
  },
  {
    No:"13",
    Disease: 'Amebiasis/Dysentery',
    Definition:"Entamoeba histolytica causes amoebiasis, often known as amoebic dysentery. Amoebiasis can manifest itself in a variety of ways, including no symptoms, mild symptoms, or severe symptoms. Lethargy, weight loss, colonic ulcerations, stomach pain, diarrhoea, or bloody diarrhoea are all possible symptoms. Inflammation and ulceration of the colon can occur, along with tissue death or perforation, resulting in peritonitis.",
    Threshold:"-",
  },
  {
    No:"14",
    Disease: 'Scabies',
    Definition:"The mite Sarcoptesscabiei causes scabies, which is a contagious skin infection. Severe itching and a pimple-like rash are the most prevalent symptoms. Tiny burrows may form on the skin from time to time.",
    Threshold:"-",
  },
]

export const entries1Grid = [
  {
    field:0,
    headerText:'Disease',
    width:'100',
    textAlign:'left'
  },
  {
    field:1,
    headerText:'Hospital District',
    width:'100',
    textAlign:'left'
  },
  {
    field:'facilityType',
    headerText:'Facility Type',
    width:'60',
    textAlign:'left'
  },
  {
    field:'date',
    headerText:'Date',
    width:'100',
    textAlign:'left'
  },
  {
    field:'age',
    headerText:'Age',
    width:'40',
    textAlign:'left'
  },
  {
    field:'gender',
    headerText:'Gender',
    width:'40',
    textAlign:'left'
  },
]

export const AnalysisGrid = [
  {
    field:'1',
    headerText:'Location',
    width:'100',
    textAlign:'left'
  },
  {
    field:'2',
    headerText:'Count',
    width:'100',
    textAlign:'left'
  },
]

export const IDSRGrid = [
  {
    field:'0',
    headerText:'Disease',
    width:'100',
    textAlign:'left'
  },  
  {
    field:'1',
    headerText:'Location',
    width:'100',
    textAlign:'left'
  },
  {
    field:'2',
    headerText:'Count',
    width:'100',
    textAlign:'left'
  },
]

export const DHIS2PunjabGrid = [
  {
    field:'0',
    headerText:'Disease',
    width:'100',
    textAlign:'left'
  },
  {
    field:'1',
    headerText:'Location',
    width:'100',
    textAlign:'left'
  },
  {
    field:'2',
    headerText:'Count',
    width:'100',
    textAlign:'left'
  },
]


export const DSSPunjabGrid = [
  {
    field:'disease',
    headerText:'Disease',
    width:'100',
    textAlign:'left'
  },
  {
    field:'gender',
    headerText:'Gender',
    width:'100',
    textAlign:'left'
  },
  {
    field:'age',
    headerText:'Age',
    width:'100',
    textAlign:'left'
  },
  {
    field:'hospital',
    headerText:'Hospital',
    width:'100',
    textAlign:'left'
  },
  {
    field:'visit_count',
    headerText:'Count',
    width:'100',
    textAlign:'left'
  },
]

export const entriesGrid = [
  {
    field:'Provisional Diagnose',
    headerText:'Disease',
    width:'100',
    textAlign:'left'
  },
  {
    field:'0',
    headerText:'Facility',
    width:'100',
    textAlign:'left'
  },
  {
    field:'Facility Type',
    headerText:'Type',
    width:'100',
    textAlign:'left'
  },
  {
    field:'Gender',
    headerText:'Gender',
    width:'100',
    textAlign:'left'
  },
  {
    field:'Age',
    headerText:'Age',
    width:'100',
    textAlign:'left'
  },
  {
    field:'Town/Tehsil',
    headerText:'Location',
    width:'100',
    textAlign:'left'
  },
  {
    field:'Locality',
    headerText:'Patient Locality',
    width:'40',
    textAlign:'left'
  },
  {
    field:'Visit Date',
    headerText:'Date',
    textAlign:'left',
    width:'40',
  }
]

export const entriesGrid2 = [
  {
    field:'ProvisionalDiagnose',
    headerText:'Disease',
    width:'60',
    textAlign:'left'
  },
  {
    field:'HospitalVisited',
    headerText:'Facility',
    width:'60',
    textAlign:'left'
  },
  {
    field:'FacilityType',
    headerText:'Type',
    width:'20',
    textAlign:'left'
  },
  {
    field:'Gender',
    headerText:'Gender',
    width:'40',
    textAlign:'left'
  },
  {
    field:'AgeY',
    headerText:'Age',
    width:'20',
    textAlign:'left'
  },
  {
    field:'Town',
    headerText:'Location',
    width:'40',
    textAlign:'left'
  },
  {
    field:'Locality',
    headerText:'Patient Locality',
    width:'40',
    textAlign:'left'
  },
  {
    field:'VisitDate',
    headerText:'Date',
    textAlign:'left',
    width:'40',
  }
]

export const diseasesGrid = [
  {
    field: 'Disease',
    headerText: 'Disease',
    textAlign: 'Left',
    width: '80',
  },
  {
    field: 'Total',
    headerText: 'Total',
    width: '80',
    textAlign: 'Center',
  },
  {
    field: 'Jan',
    headerText: 'Jan',
    width: '80',
    textAlign: 'Center',
  },
  { field: 'Feb',
    headerText: 'Feb',
    width: '80',
    textAlign: 'Center',
  },
  {
    field: 'Mar',
    headerText: 'Mar',
    textAlign: 'Center',
    width: '80',
  },
  {
    headerText: 'Apr',
    field: 'Apr',
    textAlign: 'Center',
    width: '80',
  },
  {
    field: 'May',
    headerText: 'May',
    width: '80',
    textAlign: 'Center',
  },

  {
    field: 'June',
    headerText: 'June',
    width: '80',
    textAlign: 'Center',
  },
  {
    field: 'July',
    headerText: 'July',
    width: '80',
    textAlign: 'Center',
  },
  {
    field: 'Aug',
    headerText: 'Aug',
    width: '80',
    textAlign: 'Center',
  },
  {
    field: 'Sept',
    headerText: 'Sept',
    width: '80',
    textAlign: 'Center',
  },
  {
    field: 'Oct',
    headerText: 'Oct',
    width: '80',
    textAlign: 'Center',
  }
];

export const DHIS2Grid = [
  {
    field: 'District',
    headerText: 'District',
    textAlign: 'Left',
    width: '120',
  },
  {
    field: 'Dengue',
    headerText: 'Dengue Fever',
    width: '80',
    textAlign: 'Center',
  },
  { field: 'Typhoid',
    headerText: 'Typhoid',
    width: '80',
    textAlign: 'Center',
  },
  {
    field: 'Dog Bite',
    headerText: 'Dog Bites',
    textAlign: 'Center',
    width: '80',
  },
  {
    headerText: 'Chikungunya',
    field: 'Chikungunya',
    textAlign: 'Center',
    width: '80',
  },
  {
    field: 'Leshmaniasis',
    headerText: 'Leshmaniasis',
    width: '80',
    textAlign: 'Center',
  },

  {
    field: 'Scabies',
    headerText: 'Scabies',
    width: '80',
    textAlign: 'Center',
  },
  {
    field: 'TB',
    headerText: 'TB',
    width: '80',
    textAlign: 'Center',
  },
  {
    field: 'Dairrhea',
    headerText: 'Dairrhea',
    width: '80',
    textAlign: 'Center',
  },
  {
    field: 'Malaria',
    headerText: 'Malaria',
    width: '80',
    textAlign: 'Center',
  },
  {
    field: 'CCHF',
    headerText: 'CCHF',
    width: '80',
    textAlign: 'Center',
  }
];

export const dataSourcesGrid = [
  {
    field: 'No',
    headerText: 'No.',
    textAlign: 'Left',
    width: '10',
  },
  {
    field: 'DataSource',
    headerText: 'Short Form',
    textAlign: 'Left',
    width: '20',
  },
  {
    field: 'FullForm',
    headerText: 'Full Name',
    textAlign: 'Left',
    width: '50',
  },
  {
    field: 'Link',
    headerText: 'Link',
    textAlign: 'Left',
    width: '30',
  },
]

export const dataSourcesData = [
  {
    No:"1",
    DataSource: 'DSS',
    FullForm:"Disease Surveillance System, Punjab.",
    Link: "https://dss.punjab.gov.pk/",
  },
  {
    No:"2",
    DataSource: 'DHIS',
    FullForm:"District Health Information System, Punjab.",
    Link: "https://dhispb.com/",
  },
  {
    No:"3",
    DataSource: 'DHIS-2',
    FullForm:"District Health Information System - 2, Punjab.",
    Link: "https://dhispb.com/",
  },
  {
    No:"4",
    DataSource: 'ADRS',
    FullForm:"Animal Disease Reporting System, Punjab.",
    Link: "https://adrspunjab.gov.pk",
  },
  {
    No:"5",
    DataSource: 'COVID - 19',
    FullForm:"Dashboard for COVID 19, Governament Of Pakistan.",
    Link: "https://covid.gov.pk/",
  },
  {
    No:"6",
    DataSource: 'Dengue',
    FullForm:"Dashboard for Dengue Punjab",
    Link: "https://pitb.gov.pk/dats",
  },
  // {
  //   No:"7",
  //   DataSource: 'MIS Dashboard',
  //   FullForm:"Health Management Information System",
  //   Link: "nhsrc.pk/index.php",
  // },
]

export const dataSources = [
  {
    No:"1",
    DataSource: 'DHIS-2',
    FullForm:"District Health Information System - 2, Punjab.",
    to:"/dhis2punjab",
    Diseases:"12 Diseases Reported",
    icon:<img className='flex align-middle justify-center rounded-xl h-16 w-auto bg-white dark:bg-secondary-dark-bg' src={dhis2Logo}/>,
    iconColor: '#03C9D7',
    iconBg: '#E5FAFB',
    Districts:'36+ Districts',
    Hospitals:'100+ Hospitals',
  },
  {
    No:"2",
    DataSource: 'IDSR',
    FullForm:"Integrated Disease Surveillance & Response.",
    to:"/IDSR",
    Diseases:"0 Diseases Reported",
    icon:<img className='flex align-middle justify-center rounded-xl h-16 w-auto bg-white dark:bg-secondary-dark-bg' src={nihLogo}/>,
    iconColor: '#03C9D7',
    iconBg: '#E5FAFB',
    Districts:'0 Districts',
    Hospitals:'100+ Hospitals',
  },
  {
    No:"3",
    DataSource: 'DSS',
    FullForm:"Disease Surveillance System, Punjab.",
    Link: "https://dss.punjab.gov.pk/",
    to:"/DSSPunjab",
    Diseases:"9 Diseases Reported",
    icon:<img className='flex align-middle justify-center rounded-xl h-16 w-auto bg-white dark:bg-secondary-dark-bg' src={gopGreen}/>,
    iconColor: '#03C9D7',
    iconBg: '#E5FAFB',
    Districts:'36+ Districts',
    Hospitals:'100+ Hospitals',
  },
  {
    No:"4",
    DataSource: 'ADRS',
    FullForm:"Animal Disease Reporting System, Punjab",
    to:"/ADRSPunjab",
    Diseases:"0 Diseases Reported",
    icon:<img className='flex align-middle justify-center rounded-xl h-16 w-auto bg-white dark:bg-secondary-dark-bg' src={adrsLogo}/>,
    iconColor: '#03C9D7',
    iconBg: '#E5FAFB',
    Districts:'0 Districts',
    Hospitals:'100+ Hospitals',
  },
  // {
  //   No:"5",
  //   DataSource: 'MIS Dashboard',
  //   FullForm:"Health Management Information System",
  //   to:"/MIS",
  //   Diseases:"4 Diseases Reported",
  //   icon:<img className='flex align-middle justify-center rounded-xl h-16 w-auto bg-white dark:bg-secondary-dark-bg' src={gopGreen}/>
  // },
]

export const relatedData = [
  {
    No:"1",
    Name: 'Data Sources',
    FullForm:"Data Sources which help in expanding our database.",
    to:"/DataSources",
    Count:"4",
    icon:<img className='flex align-middle justify-center rounded-xl h-20 w-auto bg-white dark:bg-secondary-dark-bg' src={documentLogo}/>,
    iconColor: '#03C9D7',
    iconBg: '#E5FAFB',

  },
  {
    No:"2",
    Name: 'Diseases',
    FullForm:"Disease count which are reported in the dashboard",
    to:"/definitions",
    Count:"13",
    icon:<img className='flex align-middle justify-center rounded-xl h-20 w-auto bg-white dark:bg-secondary-dark-bg' src={covidLogo}/>
  },
  {
    No:"3",
    Name: 'Districts',
    FullForm:"Districts from which the reports are generated.",
    to:"/GeoTags",
    Count:"36+",
    icon:<img className='flex align-middle justify-center rounded-xl h-20 w-auto bg-white dark:bg-secondary-dark-bg' src={districtLogo}/>
  },
  {
    No:"4",
    Name: 'Hospitals',
    FullForm:"Geotags/Hospitals from where the cases are reported",
    to:"/GeoTags",
    Count:"300+",
    icon:<img className='flex align-middle justify-center rounded-xl h-20 w-auto bg-white dark:bg-secondary-dark-bg' src={hospitalLogo}/>
  },
]

export const importantLinksData = [
  {
    No:"1",
    DataSource: 'POHA',
    FullForm:"Pak One Health Alliance",
    Link: "https://pakonehealth.org/",
    icon:<img className='flex align-middle justify-center rounded-xl h-10 w-auto bg-white' src={pohaLogo}/>,
  },
  {
    No:"2",
    DataSource: 'EBS',
    FullForm:"Event Based Surveillance System",
    Link: "https://pakonehealth.org/ebs/",
    icon:<img className='flex align-middle justify-center rounded-xl h-10 w-auto bg-white' src={pohaLogo}/>,
  },
  {
    No:"3",
    DataSource: 'IDRS',
    FullForm:"IDSR",
    Link: "https://idsr.punjab.gov.pk/",
    icon:<img className='flex align-middle justify-center rounded-xl h-10 w-auto bg-white' src={nihLogo}/>,
  },
  {
    No:"4",
    DataSource: 'WHO',
    FullForm:"World Health Organization",
    Link: "https://www.who.int/",
    icon:<img className='flex align-middle justify-center rounded-xl h-10 w-auto bg-white' src={who}/>,
  },
  {
    No:"5",
    DataSource: 'EP',
    FullForm:"Ending Pandemics",
    Link: "https://endingpandemics.org",
    icon:<img className='flex align-middle justify-center rounded-xl h-10 w-auto bg-white' src={ep}/>,
  },
  {
    No:"6",
    DataSource: 'MediSys',
    FullForm:"MediSys",
    Link: "https://medisys.newsbrief.eu/medisys/countryedition/en/PK.html",
    icon:<img className='flex align-middle justify-center rounded-xl h-10 w-auto bg-white' src={Medisys}/>,
  },
  {
    No:"7",
    DataSource: 'ProMED',
    FullForm:"ProMED",
    Link: "https://promedmail.org",
    icon:<img className='flex align-middle justify-center rounded-xl h-10 w-auto bg-white' src={Promed}/>,
  },
]

export const diseasesData = [
  {
    Disease: 'Avian Influenza',
    Jan: 40,
    Feb:10,
    Mar:10,
    Apr:50,
    May:10,
    June:20,
    July:20,
    Aug:40,
    Sept:2,
    Oct:23,
    Nov:20,
    Dec:25,
    Total: 270,
  },
  {
    Disease: 'COVID - 19',
    StatusBg: '#8BE78B',
    Jan: 40,
    Feb:10,
    Mar:10,
    Apr:50,
    May:10,
    June:20,
    July:20,
    Aug:40,
    Sept:2,
    Oct:23,
    Nov:20,
    Dec:25,
    Total: 270,
  },
  {
    Disease: 'Anthrax',
    StatusBg: '#03C9D7',
    Jan: 40,
    Feb:10,
    Mar:10,
    Apr:50,
    May:10,
    June:20,
    July:20,
    Aug:40,
    Sept:2,
    Oct:23,
    Nov:20,
    Dec:25,
    Total: 270,
  },
  {
    Disease: 'Scabies',
    StatusBg: '#FF5C8E',
    Jan: 40,
    Feb:10,
    Mar:10,
    Apr:50,
    May:10,
    June:20,
    July:20,
    Aug:40,
    Sept:2,
    Oct:23,
    Nov:20,
    Dec:25,
    Total: 270,
  },
  {
    Disease: 'Rabies',
    Jan: 40,
    Feb:10,
    Mar:10,
    Apr:50,
    May:10,
    June:20,
    July:20,
    Aug:40,
    Sept:2,
    Oct:23,
    Nov:20,
    Dec:25,
    Total: 270,
  },
  {
    Disease: 'Malaria',
    Jan: 40,
    Feb:10,
    Mar:10,
    Apr:50,
    May:10,
    June:20,
    July:20,
    Aug:40,
    Sept:2,
    Oct:23,
    Nov:20,
    Dec:25,
    Total: 270,
  },
  {
    Disease: 'Dengue',
    Jan: 40,
    Feb:10,
    Mar:10,
    Apr:50,
    May:10,
    June:20,
    July:20,
    Aug:40,
    Sept:2,
    Oct:23,
    Nov:20,
    Dec:25,
    Total: 270,
  },
  {
    Disease: 'Chikungunya',
    Jan: 40,
    Feb:10,
    Mar:10,
    Apr:50,
    May:10,
    June:20,
    July:20,
    Aug:40,
    Sept:2,
    Oct:23,
    Nov:20,
    Dec:25,
    Total: 270,
  },
  {
    Disease: 'CCHF',
    Jan: 40,
    Feb:10,
    Mar:10,
    Apr:50,
    May:10,
    June:20,
    July:20,
    Aug:40,
    Sept:2,
    Oct:23,
    Nov:20,
    Dec:25,
    Total: 270,
    },
  {
    Disease: 'Brucellosis',
    Jan: 40,
    Feb:10,
    Mar:10,
    Apr:50,
    May:10,
    June:20,
    July:20,
    Aug:40,
    Sept:2,
    Oct:23,
    Nov:20,
    Dec:25,
    Total: 270,
  },
  {
    Disease: 'Amebiasis',

    Jan: 40,
    Feb:10,
    Mar:10,
    Apr:50,
    May:10,
    June:20,
    July:20,
    Aug:40,
    Sept:2,
    Oct:23,
    Nov:20,
    Dec:25,
    Total: 270,
    },
];


export const pandemicData = [
  {
    Disease: 'Avian Influenza',
    Jan: 1,
    Feb: '-',
    Mar: '-',
    Apr: '-',
    May: 1,
    June: '-',
    July: '-',
    Aug: '-',
    Sept: '-',
    Oct: '-',
    Nov: '-',
    Dec: '-',
    Total: '1',
  },
  {
    Disease: 'COVID - 19',
    Jan: 12,
    Feb: '-',
    Mar:1,
    Apr: '-',
    May: '-',
    June: '-',
    July: '-',
    Aug: '-',
    Sept: '-',
    Oct: '-',
    Nov: '-',
    Dec: '-',
    Total: '1',
  },
];

export const waterborneData = [
  {
    Disease: 'Amebiasis',

    Jan:  '-',
    Feb: '-',
    Mar: '-',
    Apr: '-',
    May: '-',
    June: '-',
    July: '-',
    Aug: '-',
    Sept: '-',
    Oct: '-',
    Nov: '-',
    Dec: '-',
    Total:  '-',
    },  
    {
      Disease: 'Cholera',
      Jan:  '-',
      Feb: '-',
      Mar: '-',
      Apr: '-',
      May: '-',
      June: '-',
      July: '-',
      Aug: '-',
      Sept: '-',
      Oct: '-',
      Nov: '-',
      Dec: '-',
      Total:  8320,
      },
      {
        Disease: 'Hepatitis (A E)',
        Jan:  '-',
        Feb: '-',
        Mar: '-',
        Apr: '-',
        May: '-',
        June: '-',
        July: '-',
        Aug: '-',
        Sept: '-',
        Oct: '-',
        Nov: '-',
        Dec: '-',
        Total:  '392',
        },
        
      {
        Disease: 'Typhoid',
    
        Jan:  '-',
        Feb: '-',
        Mar: '-',
        Apr: '-',
        May: '-',
        June: '-',
        July: '-',
        Aug: '-',
        Sept: '-',
        Oct: '-',
        Nov: '-',
        Dec: '-',
        Total:  '1562',
        },

];

export const vectorborneData = [
  {
    Disease: 'Malaria',
    Jan:  '-',
    Feb: '-',
    Mar: '-',
    Apr: '-',
    May: '-',
    June: '-',
    July: '-',
    Aug: '-',
    Sept: '-',
    Oct: 196,
    Nov: 25,
    Dec: 69,
    Total:  '84',
  },
  {
    Disease: 'Dengue',
    Jan: 346,
    Feb:334,
    Mar:406,
    Apr:487,
    May:512,
    June:446,
    July: '-',
    Aug: '-',
    Sept: '-',
    Oct: 1866,
    Nov: 1681,
    Dec: 604,
    Total:  2376,
  },
  {
    Disease: 'Chikungunya',
    Jan:  '-',
    Feb: '-',
    Mar: '-',
    Apr: '-',
    May: '-',
    June: '-',
    July: '-',
    Aug: '-',
    Sept: '-',
    Oct: '-',
    Nov: '-',
    Dec: '-',
    Total:  '-',
  },
  {
    Disease: 'CCHF',
    Jan:  '-',
    Feb: '-',
    Mar: '-',
    Apr: '-',
    May: '-',
    June: '-',
    July: '-',
    Aug: '-',
    Sept: '-',
    Oct: '-',
    Nov: '-',
    Dec: '-',
    Total:  '-',
    },
    {
      Disease: 'Leshmaniasis',
      Jan:  '-',
      Feb: '-',
      Mar: '-',
      Apr: '-',
      May: '-',
      June: '-',
      July: '-',
      Aug: '-',
      Sept: '-',
      Oct: '-',
      Nov: '-',
      Dec: '-',
      Total:  '132',
    },
    {
      Disease: 'Zika Virus',
      Jan:  '-',
      Feb: '-',
      Mar: '-',
      Apr: '-',
      May: '-',
      June: '-',
      July: '-',
      Aug: '-',
      Sept: '-',
      Oct: '-',
      Nov: '-',
      Dec: '-',
      Total:  '-',
    },
];

export const zoonoticData = [
  {
    Disease: 'Anthrax',
    Jan: '-',
    Feb: '-',
    Mar: '-',
    Apr: '-',
    May: '-',
    June: '-',
    July: '-',
    Aug: '-',
    Sept: '-',
    Oct: '-',
    Nov: '-',
    Dec: '-',
    Total:  '-',
  },
  {
    Disease: 'Dog Bites',
    Jan: 1680,
    Feb:1609,
    Mar:1530,
    Apr:1537,
    May:1514,
    June:1432,
    July:31,
    Aug: '-',
    Sept: '-',
    Oct: '-',
    Nov: '-',
    Dec: '-',
    Total: 9333,
  },
  {
    Disease: 'Brucellosis',
    Jan:  '-',
    Feb: '-',
    Mar: '-',
    Apr: '-',
    May: '-',
    June: '-',
    July: '-',
    Aug: '-',
    Sept: '-',
    Oct: '-',
    Nov: '-',
    Dec: '-',
    Total: '-',
  },  {
    Disease: 'Bovine Tuberculosis',
    Jan:  '-',
    Feb: '-',
    Mar: '-',
    Apr: '-',
    May: '-',
    June: '-',
    July: '-',
    Aug: '-',
    Sept: '-',
    Oct: '-',
    Nov: '-',
    Dec: '-',
    Total:  1287,
  },
  {
    Disease: 'Salmonellosis',
    Jan:  '-',
    Feb: '-',
    Mar: '-',
    Apr: '-',
    May: '-',
    June: '-',
    July: '-',
    Aug: '-',
    Sept: '-',
    Oct: '-',
    Nov: '-',
    Dec: '-',
    Total:  '-',
  },
];

export const avianinfluenza22 = [
  {
  HospitalDistrict: "Gujrat",
  HospitalVisited: "THQ Hospital Lalamusa",
  FacilityType: "THQ",
  ProvisionalDiagnose: "AVIAN INFLUENZA",
  VisitDate: "5/10/2022",
  Latitude: "1",
  Longitude: "1",
  Name: "Manza",
  AgeY: "45",
  AgeM: "0",
  Gender: "female",
  Town: "Kharian",
  Locality: "Lalamusa No.1, Kharian, Gujrat",
  PatientDistrict: "Gujrat"
}];

export const dhis2Dec = [
  {District: 'Lahore', Dengue : 1680},
]

export const dogBiteData = [
  {month: 'Jan', count : 1680},
  {month: 'Feb', count : 1609},
  {month: 'Mar', count : 1530},
  {month: 'Apr', count : 1537},
  {month: 'May', count : 1514},
  {month: 'Jun', count : 1432},
  {month: 'July', count : 31},
]

export const dengueData = [
  {month: 'Jan', count : 346},
  {month: 'Feb', count : 334},
  {month: 'Mar', count : 406},
  {month: 'Apr', count : 487},
  {month: 'May', count : 512},
  {month: 'Jun', count : 446},
  {month: 'July', count : 0},
]

export const lineChartData = [
  [
    { x: new Date(2021, 1, 1), y: 43 },
    { x: new Date(2021, 4, 4), y: 17 },
    { x: new Date(2021, 7, 7), y: 52 },
    { x: new Date(2021, 10, 10), y: 50 },
    { x: new Date(2022, 1, 1), y: 242 },
    { x: new Date(2022, 4, 4), y: 19 },
  ],
  [
    { x: new Date(2021, 1, 1), y: 1 },
    { x: new Date(2021, 4, 4), y: 1 },
    { x: new Date(2021, 7, 7), y: 0 },
    { x: new Date(2021, 10, 10), y: 0 },
    { x: new Date(2022, 1, 1), y: 0 },
    { x: new Date(2022, 4, 4), y: 0 },
  ],

  [
    { x: new Date(2021, 1, 1), y: 1 },
    { x: new Date(2021, 4, 4), y: 0 },
    { x: new Date(2021, 7, 7), y: 2 },
    { x: new Date(2021, 10, 10), y: 0 },
    { x: new Date(2022, 1, 1), y: 0 },
    { x: new Date(2022, 4, 4), y: 0 },
  ],
  [
    { x: new Date(2021, 1, 1), y: 1 },
    { x: new Date(2021, 4, 4), y: 0 },
    { x: new Date(2021, 7, 7), y: 2 },
    { x: new Date(2021, 10, 10), y: 0 },
    { x: new Date(2022, 1, 1), y: 0 },
    { x: new Date(2022, 4, 4), y: 0 },
  ],
  [
    { x: new Date(2021, 1, 1), y: 1 },
    { x: new Date(2021, 4, 4), y: 0 },
    { x: new Date(2021, 7, 7), y: 2 },
    { x: new Date(2021, 10, 10), y: 0 },
    { x: new Date(2022, 1, 1), y: 0 },
    { x: new Date(2022, 4, 4), y: 0 },
  ],
  [
    { x: new Date(2021, 1, 1), y: 1 },
    { x: new Date(2021, 4, 4), y: 0 },
    { x: new Date(2021, 7, 7), y: 2 },
    { x: new Date(2021, 10, 10), y: 0 },
    { x: new Date(2022, 1, 1), y: 0 },
    { x: new Date(2022, 4, 4), y: 0 },
  ],
  [
    { x: new Date(2021, 1, 1), y: 1 },
    { x: new Date(2021, 4, 4), y: 0 },
    { x: new Date(2021, 7, 7), y: 2 },
    { x: new Date(2021, 10, 10), y: 0 },
    { x: new Date(2022, 1, 1), y: 0 },
    { x: new Date(2022, 4, 4), y: 0 },
  ],
  [
    { x: new Date(2021, 1, 1), y: 1 },
    { x: new Date(2021, 4, 4), y: 0 },
    { x: new Date(2021, 7, 7), y: 2 },
    { x: new Date(2021, 10, 10), y: 0 },
    { x: new Date(2022, 1, 1), y: 0 },
    { x: new Date(2022, 4, 4), y: 0 },
  ],
];

export const linePriorityData = [
  [
    { x: new Date(2021, 1, 1), y: 20 },
    { x: new Date(2021, 4, 4), y: 42 },
    { x: new Date(2021, 7, 7), y: 4 },
    { x: new Date(2021, 10, 10), y: 4 },
    { x: new Date(2022, 1, 1), y: 1 },
    { x: new Date(2022, 4, 4), y: 0 },
    { x: new Date(2022, 7, 7), y: 0 },
  ],
  [
    { x: new Date(2021, 1, 1), y: 8157 },
    { x: new Date(2021, 4, 4), y: 5052 },
    { x: new Date(2021, 7, 7), y: 5341 },
    { x: new Date(2021, 10, 10), y: 4528 },
    { x: new Date(2022, 1, 1), y: 4819 },
    { x: new Date(2022, 4, 4), y: 4483 },
    { x: new Date(2022, 7, 7), y: 49 },
  ],

  [
    { x: new Date(2021, 1, 1), y: 1986 },
    { x: new Date(2021, 4, 4), y: 2199 },
    { x: new Date(2021, 7, 7), y: 1430 },
    { x: new Date(2021, 10, 10), y: 2300 },
    { x: new Date(2022, 1, 1), y: 1086 },
    { x: new Date(2022, 4, 4), y: 1444 },
    { x: new Date(2022, 7, 7), y: 3 },
  ],
  [
    { x: new Date(2021, 1, 1), y: 0 },
    { x: new Date(2021, 4, 4), y: 0 },
    { x: new Date(2021, 7, 7), y: 3 },
    { x: new Date(2021, 10, 10), y: 0 },
    { x: new Date(2022, 1, 1), y: 0 },
    { x: new Date(2022, 4, 4), y: 0 },
    { x: new Date(2022, 7, 7), y: 0 },
  ],
  [
    { x: new Date(2021, 1, 1), y: 0 },
    { x: new Date(2021, 4, 4), y: 0 },
    { x: new Date(2021, 7, 7), y: 0 },
    { x: new Date(2021, 10, 10), y: 0 },
    { x: new Date(2022, 1, 1), y: 0 },
    { x: new Date(2022, 4, 4), y: 0 },
    { x: new Date(2022, 7, 7), y: 0 },
  ],
  [
    { x: new Date(2021, 1, 1), y: 0 },
    { x: new Date(2021, 4, 4), y: 0 },
    { x: new Date(2021, 7, 7), y: 0 },
    { x: new Date(2021, 10, 10), y: 0 },
    { x: new Date(2022, 1, 1), y: 0 },
    { x: new Date(2022, 4, 4), y: 0 },
    { x: new Date(2022, 7, 7), y: 0 },
  ],
];

export const dropdownData = [
  {
    Id: '1',
    Time: 'March 2021',
  },
  {
    Id: '2',
    Time: 'April 2021',
  }, {
    Id: '3',
    Time: 'May 2021',
  },
];

export const SparklineAreaData = [
  { x: 1, yval: 2 },
  { x: 2, yval: 6 },
  { x: 3, yval: 8 },
  { x: 4, yval: 5 },
  { x: 5, yval: 10 },

];

export const lineCustomSeries = [
  { dataSource: lineChartData[0],
    xName: 'x',
    yName: 'y',
    name: 'Leishmaniasis',
    width: '2',
    marker: { visible: true, width: 10, height: 10 },
    type: 'Line' },

  { dataSource: lineChartData[1],
    xName: 'x',
    yName: 'y',
    name: 'Malaria',
    width: '2',
    marker: { visible: true, width: 10, height: 10 },
    type: 'Line' },

  { dataSource: lineChartData[2],
    xName: 'x',
    yName: 'y',
    name: 'Avian Influenza',
    width: '2',
    marker: { visible: true, width: 10, height: 10 },
    type: 'Line' },

    { dataSource: lineChartData[3],
      xName: 'x',
      yName: 'y',
      name: 'Chikungunya',
      width: '2',
      marker: { visible: true, width: 10, height: 10 },
      type: 'Line' },

  { dataSource: lineChartData[4],
    xName: 'x',
    yName: 'y',
    name: 'TB',
    width: '2',
    marker: { visible: true, width: 10, height: 10 },
    type: 'Line' },

  { dataSource: lineChartData[5],
    xName: 'x',
    yName: 'y',
    name: 'Brucellosis',
    width: '2',
    marker: { visible: true, width: 10, height: 10 },
    type: 'Line' },

    { dataSource: lineChartData[6],
      xName: 'x',
      yName: 'y',
      name: 'Amebiasis',
      width: '2',
      marker: { visible: true, width: 10, height: 10 },
      type: 'Line' },
      
    { dataSource: lineChartData[7],
      xName: 'x',
      yName: 'y',
      name: 'Scabies',
      width: '2',
      marker: { visible: true, width: 10, height: 10 },
      type: 'Line' },
];

export const lineZoonoticSeries = [
  { dataSource: lineChartData[0],
    xName: 'x',
    yName: 'y',
    name: 'Anthrax',
    width: '2',
    marker: { visible: true, width: 10, height: 10 },
    type: 'Line' },

  { dataSource: lineChartData[1],
    xName: 'x',
    yName: 'y',
    name: 'Bovine Tuberculosis',
    width: '2',
    marker: { visible: true, width: 10, height: 10 },
    type: 'Line' },

  { dataSource: lineChartData[2],
    xName: 'x',
    yName: 'y',
    name: 'Brucellosis',
    width: '2',
    marker: { visible: true, width: 10, height: 10 },
    type: 'Line' },

    { dataSource: lineChartData[3],
      xName: 'x',
      yName: 'y',
      name: 'Rabies',
      width: '2',
      marker: { visible: true, width: 10, height: 10 },
      type: 'Line' },

  { dataSource: lineChartData[4],
    xName: 'x',
    yName: 'y',
    name: 'Dog Bites',
    width: '2',
    marker: { visible: true, width: 10, height: 10 },
    type: 'Line' },

  { dataSource: lineChartData[5],
    xName: 'x',
    yName: 'y',
    name: 'Salmonellosis',
    width: '2',
    marker: { visible: true, width: 10, height: 10 },
    type: 'Line' },
];

export const linePrioritySeries = [
  { dataSource: linePriorityData[0],
    xName: 'x',
    yName: 'y',
    name: 'COVID 19',
    width: '2',
    marker: { visible: true, width: 10, height: 10 },
    type: 'Line' },

  { dataSource: linePriorityData[1],
    xName: 'x',
    yName: 'y',
    name: 'Dog Bites',
    width: '2',
    marker: { visible: true, width: 10, height: 10 },
    type: 'Line' },

  { dataSource: linePriorityData[2],
    xName: 'x',
    yName: 'y',
    name: 'Dengue',
    width: '2',
    marker: { visible: true, width: 10, height: 10 },
    type: 'Line' },

    { dataSource: linePriorityData[3],
      xName: 'x',
      yName: 'y',
      name: 'Anthrax',
      width: '2',
      marker: { visible: true, width: 10, height: 10 },
      type: 'Line' },

  { dataSource: linePriorityData[4],
    xName: 'x',
    yName: 'y',
    name: 'Salmonellosis',
    width: '2',
    marker: { visible: true, width: 10, height: 10 },
    type: 'Line' },

  { dataSource: linePriorityData[5],
    xName: 'x',
    yName: 'y',
    name: 'CCHF',
    width: '2',
    marker: { visible: true, width: 10, height: 10 },
    type: 'Line' },
];

export const pieChartData = [
  { x: 'CCHF', y: 18, text: '18%' },
  { x: 'Covid-19', y: 8, text: '8%' },
  { x: 'Chikungunya', y: 15, text: '15%' },
  { x: 'Anthrax', y: 11, text: '11%' },
  { x: 'Dengue', y: 18, text: '18%' },
  { x: 'Malaria', y: 14, text: '14%' },
  { x: 'Rabies', y: 16, text: '16%' },
];

export const contextMenuItems = [
  'AutoFit',
  'AutoFitAll',
  'SortAscending',
  'SortDescending',
  'Copy',
  'Edit',
  'Delete',
  'Save',
  'Cancel',
  'PdfExport',
  'ExcelExport',
  'CsvExport',
  'FirstPage',
  'PrevPage',
  'LastPage',
  'NextPage',
];

export const ecomPieChartData = [
  { x: '2018', y: 18, text: '35%' },
  { x: '2019', y: 18, text: '15%' },
  { x: '2020', y: 18, text: '25%' },
  { x: '2021', y: 18, text: '25%' },
];

export const stackedChartData = [
  [
    { x: 'Q1 21', y: 1986 },
    { x: 'Q2 21', y: 2199 },
    { x: 'Q3 21', y: 1430 },
    { x: 'Q4 21', y: 2300 },
    { x: 'Q1 22', y: 1086 },
    { x: 'Q2 22', y: 1444 },
    { x: 'Q3 22', y: 3 },
    
  ],
];

export const stackedCustomSeries = [

  { dataSource: stackedChartData[0],
    xName: 'x',
    yName: 'y',
    name: 'Dengue Fever',
    type: 'StackingColumn',
    background: 'blue',

  },

];

export const stackedPrimaryXAxis = {
  majorGridLines: { width: 0 },
  minorGridLines: { width: 0 },
  majorTickLines: { width: 0 },
  minorTickLines: { width: 0 },
  interval: 1,
  lineStyle: { width: 0 },
  labelIntersectAction: 'Rotate45',
  valueType: 'Category',
};

export const stackedPrimaryYAxis = {
  lineStyle: { width: 0 },
  minimum: 1000,
  maximum: 2500,
  interval: 500,
  majorTickLines: { width: 0 },
  majorGridLines: { width: 1 },
  minorGridLines: { width: 1 },
  minorTickLines: { width: 0 },
  labelFormat: '{value}',
};

export const hospDataGrid = [
  {
    field: 'group',
    headerText: 'District',
    width: '20',
    textAlign: 'Left',
  },
  {
    field: 'group_series_0',
    headerText: 'Hospital Name',
    width: '140',
    textAlign: 'Left',
  },
];

export const hospGrid = [
  {
    field: 'id',
    headerText: 'Sr no.',
    textAlign: 'Left',
    width: '15',
  },
  {
    field: 'district',
    headerText: 'District',
    width: '20',
    textAlign: 'Left',
  },
  {
    field: 'Name',
    headerText: 'Hospital Name',
    width: '140',
    textAlign: 'Left',
  },
];

export const attockHosp = [
  {
    id:'1',
    district:'Attock',
    Name:'THQ Hospital Pindi Geb',
  },
  {
    id:'2',
    district:'Attock',
    Name:'THQ Hospital Jand',
  },
  {       
    id:'3',
    district:'Attock',
    Name:'THQ Hospital Hazro',    
  },
  {
    id:'4',
    district:'Attock',
    Name:'THQ Hospital Fateh Jang',    
  },
  {
    id:'5',
    district:'Chiniot',
    Name:'BHU 241 Mangoana',    
  },
  {
    id:'6',
    district:'Mian Wali',
    Name:'BHU Abba Khel',    
  },
  {
    id:'7',
    district:'Gujrawala',
    Name:'Al Raee Hospital',    
  },
  {
    id:'8',
    district:'Nankana Sahib',
    Name:'Al Fazal Children Hospital',    
  },
  {
    id:'9',
    district:'Faislabad',
    Name:'Al Shifa Medical Centre',    
  },
  {
    id:'10',
    district:'Sailkot',
    Name:'Allama Iqbal Memorial Hospital',    
  },
  {
    id:'11',
    district:'Faislabad',
    Name:'Allied Hospital, Jail Road',    
  },
  {
    id:'12',
    district:'Gujrat',
    Name:'Aziz Bhatti Saeed Hospital, Bhimber Road',    
  },
  {
    id:'13',
    district:'Bahawalpur',
    Name:'Bahawal Vectoria Hospital, Circular Road',    
  },
  {
    id:'13',
    district:'Vehari',
    Name:'BHU 1/WB',    
  },
  {
    id:'14',
    district:'Jhelum',
    Name:'BHU Adrana',    
  },
  {
    id:'15',
    district:'Faislabad',
    Name:'Children Hospital',    
  },
  {
    id:'16',
    district:'Sialkot',
    Name:'Civil Hospital',    
  },
];

export const hospitalList = [
  {group:"Attock",group_series_0:"DHQ Hospital Attock"},
  {group:"Attock",group_series_0:"THQ Hassan Abdal"},
  {group:"Attock",group_series_0:"THQ Hospital Fateh Jang"},
  {group:"Attock",group_series_0:"THQ Hospital Hazro"},
  {group:"Attock",group_series_0:"THQ Hospital Jand"},
  {group:"Attock",group_series_0:"THQ Hospital Pindi Gheb"},
  {group:"Bahawalpur",group_series_0:"BHU BASTI RANA IQBAL"},
  {group:"Bahawalpur",group_series_0:"BHU CHAK NO. 39/DNB"},
  {group:"Bahawalpur",group_series_0:"BHU CHAK NO. 88/F"},
  {group:"Bahawalpur",group_series_0:"BHU CHAK NO.8/DNB"},
  {group:"Bahawalpur",group_series_0:"BHU HAKRA"},
  {group:"Bahawalpur",group_series_0:"BHU KOTLA QAIM KHAN"},
  {group:"Bahawalpur",group_series_0:"BHU SANJAR"},
  {group:"Bahawalpur",group_series_0:"BHU SYED IMAM SHAH"},
  {group:"Bahawalpur",group_series_0:"Bahawal Victoria Hospital, Circular Road, Bahawalpur"},
  {group:"Bahawalpur",group_series_0:"RHC KHUTRI BUNGLOW"},
  {group:"Bahawalpur",group_series_0:"RHC UCH SHARIF"},
  {group:"Bahawalpur",group_series_0:"THQ Hospital Ahmadpur East"},
  {group:"Bahawalpur",group_series_0:"THQ Hospital Hasilpur"},
  {group:"Bahawalpur",group_series_0:"THQ Khairpur Tamewali"},
  {group:"Bahawalpur",group_series_0:"THQ Yazman"},
  {group:"Bhakkar",group_series_0:"DHQ Hospital Bhakkar"},
  {group:"Bhakkar",group_series_0:"THQ Hospital Daryakhan"},
  {group:"Bhakkar",group_series_0:"THQ Hospital Kalurkot"},
  {group:"Bhakkar",group_series_0:"THQ Hospital Mankera"},
  {group:"Bhakkar",group_series_0:"edo Office Bhakkar"},
  {group:"Chakwal",group_series_0:"BHU NEELA"},
  {group:"Chakwal",group_series_0:"Ch. Pervaiz Elahi City Hospital Talagang"},
  {group:"Chakwal",group_series_0:"DHQ Chakwal"},
  {group:"Chakwal",group_series_0:"THQ Choa Saiden Shah"},
  {group:"Chakwal",group_series_0:"THQ TALAGANG"},
  {group:"Chakwal",group_series_0:"THQ Trauma Kallar Kahar"},
  {group:"Chakwal",group_series_0:"edo Office Chakwal"},
  {group:"Chiniot",group_series_0:"BHU 241 Mangoana"},
  {group:"Chiniot",group_series_0:"BHU AHMED ABAD"},
  {group:"Chiniot",group_series_0:"BHU Mathrooma"},
  {group:"Chiniot",group_series_0:"DHQ Hospital Chiniot"},
  {group:"Chiniot",group_series_0:"THQ Bhowana"},
  {group:"Chiniot",group_series_0:"THQ Lalian"},
  {group:"Chiniot",group_series_0:"edo Office Chiniot"},
  {group:"Dera Ghazi Khan",group_series_0:"BHU KHAKHI"},
  {group:"Dera Ghazi Khan",group_series_0:"RHC CHOTI ZARIN"},
  {group:"Dera Ghazi Khan",group_series_0:"RHC Kala"},
  {group:"Dera Ghazi Khan",group_series_0:"RHC SARWAR WALI"},
  {group:"Dera Ghazi Khan",group_series_0:"RHC SHAH SADAR DIN"},
  {group:"Dera Ghazi Khan",group_series_0:"RHC SHAHDAN LUND"},
  {group:"Dera Ghazi Khan",group_series_0:"RHC TIBBI QASRANI"},
  {group:"Dera Ghazi Khan",group_series_0:"RHC VEHOVA"},
  {group:"Dera Ghazi Khan",group_series_0:"THQ Hospital Kot Chutta"},
  {group:"Dera Ghazi Khan",group_series_0:"THQ Hospital Taunsa"},
  {group:"Faisalabad",group_series_0:"Al-Shifa Medical Centre Faisalabad"},
  {group:"Faisalabad",group_series_0:"Allied Hospital, Jail Road, Faisalabad"},
  {group:"Faisalabad",group_series_0:"Children Hospital, Faisalabad"},
  {group:"Faisalabad",group_series_0:"DHQ Hospital, Mall Road, Faisalabad"},
  {group:"Faisalabad",group_series_0:"Government Teaching Hospital, Ghulam Muhammad Abad, Faisalabad"},
  {group:"Faisalabad",group_series_0:"Govt. General Hospital Samanabad"},
  {group:"Faisalabad",group_series_0:"RHC 469 GB"},
  {group:"Faisalabad",group_series_0:"RHC SATYANA"},
  {group:"Faisalabad",group_series_0:"THQ Hospital Jaranwala"},
  {group:"Faisalabad",group_series_0:"THQ Hospital Jhumra"},
  {group:"Faisalabad",group_series_0:"THQ Hospital Samanduri"},
  {group:"Faisalabad",group_series_0:"THQ Tandilianwala"},
  {group:"Gujranwala",group_series_0:"#<Hospital:0x0000000591d430>"},
  {group:"Gujranwala",group_series_0:"#<Hospital:0x007fcc98a873b8>"},
  {group:"Gujranwala",group_series_0:"Al Raee Hospital"},
  {group:"Gujranwala",group_series_0:"BHU BOTALA SHARAM SINGH"},
  {group:"Gujranwala",group_series_0:"BHU BUDDA GORRAYA"},
  {group:"Gujranwala",group_series_0:"BHU CHAK RAMDAS"},
  {group:"Gujranwala",group_series_0:"BHU DANDIAN"},
  {group:"Gujranwala",group_series_0:"BHU DARGA PUR"},
  {group:"Gujranwala",group_series_0:"BHU FEROZEWALA"},
  {group:"Gujranwala",group_series_0:"BHU KALI SUBA"},
  {group:"Gujranwala",group_series_0:"BHU LALU PUR"},
  {group:"Gujranwala",group_series_0:"BHU MOKHAL SINDAAWAN"},
  {group:"Gujranwala",group_series_0:"BHU PEERO CHAK"},
  {group:"Gujranwala",group_series_0:"BHU RAJA"},
  {group:"Gujranwala",group_series_0:"DHQ Teaching Hospital, Gujranwala"},
  {group:"Gujranwala",group_series_0:"MedcareÂ International Hospital"},
  {group:"Gujranwala",group_series_0:"RHC AHMAD NAGAR"},
  {group:"Gujranwala",group_series_0:"RHC ALI PUR CHATHA"},
  {group:"Gujranwala",group_series_0:"RHC EMINABAD"},
  {group:"Gujranwala",group_series_0:"RHC GHAKHAR"},
  {group:"Gujranwala",group_series_0:"RHC Kot Ladha"},
  {group:"Gujranwala",group_series_0:"RHC Ladhey Wala Waraich"},
  {group:"Gujranwala",group_series_0:"RHC RASOOL NAGAR"},
  {group:"Gujranwala",group_series_0:"RHC SOHDRA"},
  {group:"Gujranwala",group_series_0:"RHC WAHNDO"},
  {group:"Gujranwala",group_series_0:"THQ Hospital Kamoke"},
  {group:"Gujranwala",group_series_0:"THQ Hospital Wazirabad"},
  {group:"Gujranwala",group_series_0:"THQ Noshera Virkan"},
  {group:"Gujranwala",group_series_0:"Zeeshan Welfare Hospital"},
  {group:"Gujranwala",group_series_0:"edo Office Gujranwala"},
  {group:"Gujrat",group_series_0:"Aziz Bhatti Shaheed Hospital (DHQ), Bhimber Road, Gujrat"},
  {group:"Gujrat",group_series_0:"BHU BHAG NAGAR"},
  {group:"Gujrat",group_series_0:"BHU BOLANI"},
  {group:"Gujrat",group_series_0:"BHU CHECHIAN SHAMAS"},
  {group:"Gujrat",group_series_0:"BHU CHIRYAWALA"},
  {group:"Gujrat",group_series_0:"BHU DENDAI BESA"},
  {group:"Gujrat",group_series_0:"BHU GHAKHRA KALAN"},
  {group:"Gujrat",group_series_0:"BHU KARYALA"},
  {group:"Gujrat",group_series_0:"BHU KHOHAR"},
  {group:"Gujrat",group_series_0:"BHU LAKHANWAL"},
  {group:"Gujrat",group_series_0:"BHU MAKIANA"},
  {group:"Gujrat",group_series_0:"BHU SIR DHOKE"},
  {group:"Gujrat",group_series_0:"BHU THOOTHA RAI BAHADUR"},
  {group:"Gujrat",group_series_0:"RHC MALKA"},
  {group:"Gujrat",group_series_0:"THQ Hospital Kharian"},
  {group:"Gujrat",group_series_0:"THQ Hospital Kunjah"},
  {group:"Gujrat",group_series_0:"THQ Hospital Lalamusa"},
  {group:"Gujrat",group_series_0:"THQ Hospital Sarai Alamgir"},
  {group:"Gujrat",group_series_0:"THQ Hospital Trauma Center Lalamusa"},
  {group:"Hafizabad",group_series_0:"BHU Kot Nawan Kaghoka"},{group:"Hafizabad",group_series_0:"DHQ Hospital Hafizabad"},{group:"Hafizabad",group_series_0:"RHC Jalal Pur Bhattian"},{group:"Hafizabad",group_series_0:"RHC Kaleke Mandi"},{group:"Hafizabad",group_series_0:"RHC Kasooke"},{group:"Hafizabad",group_series_0:"RHC Kolo Tarar"},{group:"Hafizabad",group_series_0:"RHC Rasool Pur Tarar"},{group:"Hafizabad",group_series_0:"RHC Sukheke Mandi"},{group:"Hafizabad",group_series_0:"RHC Vanike Tarar"},{group:"Hafizabad",group_series_0:"THQ Pindi Bhattian"},
  {group:"Hafizabad",group_series_0:"Wahlah Family Hospital"},
  {group:"Hafizabad",group_series_0:"edo Office Hafizabad"},
  {group:"Jhang",group_series_0:"Chak No.170/JB"},
  {group:"Jhang",group_series_0:"DHQ Hospital Jhang"},
  {group:"Jhang",group_series_0:"Ismail Welfare Hospital"},
  {group:"Jhang",group_series_0:"RHC Mochiwala"},
  {group:"Jhang",group_series_0:"RHC Shah Jewena"},
  {group:"Jhang",group_series_0:"Ratta Matta"},
  {group:"Jhang",group_series_0:"THQ Ahmed pur Sial"},
  {group:"Jhang",group_series_0:"THQ Hospital Hazari"},
  {group:"Jhang",group_series_0:"THQ Hospital Shorkot"},
  {group:"Jhang",group_series_0:"edo Office Jhang"},
  {group:"Jhelum",group_series_0:"BHU Adrana"},
  {group:"Jhelum",group_series_0:"BHU Ahmad Abad"},
  {group:"Jhelum",group_series_0:"BHU Ban Shaheed"},
  {group:"Jhelum",group_series_0:"BHU Banth"},
  {group:"Jhelum",group_series_0:"BHU Bhatia"},
  {group:"Jhelum",group_series_0:"BHU Chak Jani"},
  {group:"Jhelum",group_series_0:"BHU Chak Khasa/ Ph # 0544-732489"},
  {group:"Jhelum",group_series_0:"BHU Chak Latif Ullah"},
  {group:"Jhelum",group_series_0:"BHU Chak Shadi"},
  {group:"Jhelum",group_series_0:"BHU Chotala"},
  {group:"Jhelum",group_series_0:"BHU Dara Pur"},
  {group:"Jhelum",group_series_0:"BHU Dharyala Jalip"},
  {group:"Jhelum",group_series_0:"BHU G U Singh"},
  {group:"Jhelum",group_series_0:"BHU Goal Pur"},
  {group:"Jhelum",group_series_0:"BHU Huran Pur"},
  {group:"Jhelum",group_series_0:"BHU Jallo Chak"},
  {group:"Jhelum",group_series_0:"BHU Jango Raryala"},
  {group:"Jhelum",group_series_0:"BHU Kala Gujran"},
  {group:"Jhelum",group_series_0:"BHU Kalvant Pur"},
  {group:"Jhelum",group_series_0:"BHU Krounta"},
  {group:"Jhelum",group_series_0:"BHU Kundwal"},
  {group:"Jhelum",group_series_0:"BHU Mota Gharbi"},
  {group:"Jhelum",group_series_0:"BHU Pail Mirza"},
  {group:"Jhelum",group_series_0:"BHU Phulray Sayedan"},
  {group:"Jhelum",group_series_0:"BHU Pindi Said Pur"},
  {group:"Jhelum",group_series_0:"BHU Pindory"},
  {group:"Jhelum",group_series_0:"BHU Sodi Gujjar"},
  {group:"Jhelum",group_series_0:"BHU Sohan"},
  {group:"Jhelum",group_series_0:"BHU Souwal"},
  {group:"Jhelum",group_series_0:"DHQ Hospital Jhelum"},
  {group:"Jhelum",group_series_0:"RHC Civil Hospital, Khewra, Pind Dadan Khan, Jhelum"},
  {group:"Jhelum",group_series_0:"RHC Dina"},
  {group:"Jhelum",group_series_0:"RHC Domeli"},
  {group:"Jhelum",group_series_0:"RHC Jalal Pur Sharif"},
  {group:"Jhelum",group_series_0:"RHC Khalas Pur"},
  {group:"Jhelum",group_series_0:"RHC Lilla"},
  {group:"Jhelum",group_series_0:"THQ Hospital PD Khan"},
  {group:"Jhelum",group_series_0:"THQ Hospital Sohawa"},
  {group:"Jhelum",group_series_0:"edo Office Jhelum"},
  {group:"Kasur",group_series_0:"BHU KHARA"},
  {group:"Kasur",group_series_0:"DHQ HOSPITAL KASUR"},
  {group:"Kasur",group_series_0:"Govt. Aziz Bibi Hospital Roshan Bhila"},
  {group:"Kasur",group_series_0:"RHC CHANGA MANGA"},
  {group:"Kasur",group_series_0:"RHC ELLAH ABAD."},
  {group:"Kasur",group_series_0:"RHC GANDA SINGH WALA"},
  {group:"Kasur",group_series_0:"RHC HABIBABAD"},
  {group:"Kasur",group_series_0:"RHC HALLA"},
  {group:"Kasur",group_series_0:"RHC JUARA"},
  {group:"Kasur",group_series_0:"RHC KANGAN PUR"},
  {group:"Kasur",group_series_0:"RHC KHUDIAN"},
  {group:"Kasur",group_series_0:"RHC MUSTAFA ABAD"},
  {group:"Kasur",group_series_0:"RHC PHOOL NAGAR"},
  {group:"Kasur",group_series_0:"RHC RAJA JANG"},
  {group:"Kasur",group_series_0:"THQ HOSPITAL CHUNIAN"},
  {group:"Kasur",group_series_0:"THQ HOSPITAL PATTOKI"},
  {group:"Kasur",group_series_0:"THQ Kot Radha Kishan"},
  {group:"Khanewal",group_series_0:"BHU CHAK NO. 67/10-R"},
  {group:"Khanewal",group_series_0:"BHU CHAK NO. 70/15-L"},
  {group:"Khanewal",group_series_0:"BHU CHAK NO. 92/10-R"},
  {group:"Khanewal",group_series_0:"BHU CHAK NO.139/10-R"},
  {group:"Khanewal",group_series_0:"BHU CHAK NO.9/V"},
  {group:"Khanewal",group_series_0:"BHU JASSO KANWAN"},
  {group:"Khanewal",group_series_0:"BHU SARDAR PUR"},
  {group:"Khanewal",group_series_0:"BHU SHAM KOT"},
  {group:"Khanewal",group_series_0:"DHQ Hospital Khanewal"},
  {group:"Khanewal",group_series_0:"RHC KACHA KHUH"},
  {group:"Khanewal",group_series_0:"RHC Makhdoom Pur, Khanewal, Khanewal"},
  {group:"Khanewal",group_series_0:"THQ Hospital Channu"},
  {group:"Khanewal",group_series_0:"THQ Hospital Jhanian"},
  {group:"Khanewal",group_series_0:"THQ Hospital Kabir wala"},
  {group:"Khanewal",group_series_0:"edo Office Kkhanewal"},
  {group:"Khushab",group_series_0:"DHQ Khushab at Jahurabad"},
  {group:"Khushab",group_series_0:"THQ HOSPITAL NOOR PUR THAL"},
  {group:"Khushab",group_series_0:"THQ HOSPITAL QAIDABAD"},
  {group:"Khushab",group_series_0:"edo Office Khushab"},
  {group:"Lodhran",group_series_0:"BHU AIN WAHIN"},
  {group:"Lodhran",group_series_0:"DHQ Hospital Lodhran"},
  {group:"Lodhran",group_series_0:"RHC CHAK NO.231/WB"},
  {group:"Lodhran",group_series_0:"RHC MEKHDOOM AALI"},
  {group:"Lodhran",group_series_0:"THQ Hospital Dunya pur"},
  {group:"Lodhran",group_series_0:"THQ Hospital Kahror Pacca"},
  {group:"Lodhran",group_series_0:"edo Office Lodhran"},
  {group:"Mandi Bahauddin",group_series_0:"#<Hospital:0x007f3e48f46860>"},
  {group:"Mandi Bahauddin",group_series_0:"BHU BARMUSA"},
  {group:"Mandi Bahauddin",group_series_0:"BHU BHAGOWAL"},
  {group:"Mandi Bahauddin",group_series_0:"BHU KADHAR"},
  {group:"Mandi Bahauddin",group_series_0:"BHU MANGAT"},
  {group:"Mandi Bahauddin",group_series_0:"BHU NAIN RANJHA"},
  {group:"Mandi Bahauddin",group_series_0:"DHQ Hospital Mandi Bahauddin"},
  {group:"Mandi Bahauddin",group_series_0:"RHC Jokalian"},
  {group:"Mandi Bahauddin",group_series_0:"RHC Malakwal"},
  {group:"Mandi Bahauddin",group_series_0:"RHC Miana Gondal"},
  {group:"Mandi Bahauddin",group_series_0:"RHC parhianwala"},
  {group:"Mandi Bahauddin",group_series_0:"THQ Hospital Malakwal"},
  {group:"Mandi Bahauddin",group_series_0:"THQ Hospital Phalia"},
  {group:"Mianwali",group_series_0:"BHU ABBA KHEL"},
  {group:"Mianwali",group_series_0:"DHQ Hospital Mianwali"},
  {group:"Mianwali",group_series_0:"RHC Musa Khel, Mianwali, Mianwali"},
  {group:"Mianwali",group_series_0:"THQ HOSPITAL ISA KHEL"},
  {group:"Mianwali",group_series_0:"THQ Hospital Piplan"},
  {group:"Mianwali",group_series_0:"THQ LEVEL HOSPITAL KALABAGH"},
  {group:"Mianwali",group_series_0:"edo Office Mianwali"},
  {group:"Multan",group_series_0:"Children Hospital, Multan"},
  {group:"Multan",group_series_0:"Govt. Mushtaq Land THQ Hosp. Jalapur Pirwala"},
  {group:"Multan",group_series_0:"Govt. THQ Hospital Shujabad"},
  {group:"Multan",group_series_0:"Mian Muhammad Shahbaz Sharif General Hospital"},
  {group:"Multan",group_series_0:"Nishtar Hospital, Nishtar Road, Multan"},
  {group:"Muzaffargarh",group_series_0:"BHU 518-TDA"},
  {group:"Muzaffargarh",group_series_0:"BHU 576-TDA"},
  {group:"Muzaffargarh",group_series_0:"BHU Basti Chunjan"},
  {group:"Muzaffargarh",group_series_0:"BHU Ghazi Ghatt"},
  {group:"Muzaffargarh",group_series_0:"BHU Karam Dad Qureshi"},
  {group:"Muzaffargarh",group_series_0:"BHU Kotla Gamoon"},
  {group:"Muzaffargarh",group_series_0:"DHQ Hospital Muzaffargarh"},
  {group:"Muzaffargarh",group_series_0:"RHC Chowck Sarwar Shaheed"},
  {group:"Muzaffargarh",group_series_0:"RHC Gujrat"},
  {group:"Muzaffargarh",group_series_0:"RHC Murad Abad"},
  {group:"Muzaffargarh",group_series_0:"RHC Shah Jamal"},
  {group:"Muzaffargarh",group_series_0:"RHC Sheher Sultan"},
  {group:"Muzaffargarh",group_series_0:"THQ Hospital Alipur"},
  {group:"Muzaffargarh",group_series_0:"THQ Hospital Kot Adu"},
  {group:"Muzaffargarh",group_series_0:"THQ Jatoi"},
  {group:"Muzaffargarh",group_series_0:"edo Office Muzaffargarh"},
  {group:"Okara",group_series_0:"DHQ Hospital (South City) Okara"},
  {group:"Okara",group_series_0:"DHQ Hospital Okara"},
  {group:"Okara",group_series_0:"RHC RENALA KHURD"},{group:"Okara",group_series_0:"RHC SHAH BORE"},
  {group:"Okara",group_series_0:"THQ HOSPITAL DEPALPUR"},
  {group:"Okara",group_series_0:"THQ HOSPITAL HAVALI LAKHA"},
  {group:"Okara",group_series_0:"edo Office Okara"},
  {group:"Sheikhupura",group_series_0:"DHQ Teaching Hospital, Sheikhupura"},
  {group:"Sheikhupura",group_series_0:"RHC Khanqah Dogran"},
  {group:"Sheikhupura",group_series_0:"RHC SHARAQ PUR SHARIF"},
  {group:"Sheikhupura",group_series_0:"THQ Hospital Ferozewala"},
  {group:"Sheikhupura",group_series_0:"THQ Hospital Muridke"},
  {group:"Sheikhupura",group_series_0:"THQ Hospital Safdarabad"},
  {group:"Sheikhupura",group_series_0:"THQ Hospital Sharaqpur Sharif"},
  {group:"Sialkot",group_series_0:"Allama Iqbal Memorial Hospital, Sialkot"},
  {group:"Sialkot",group_series_0:"Civil Hospital Daska"},
  {group:"Sialkot",group_series_0:"Government Sardar Begum Teaching Hospital, Sialkot"},
  {group:"Sialkot",group_series_0:"THQ Hospital Pasrur"},
  {group:"Sialkot",group_series_0:"THQ Kotli Loharan"},
  {group:"Sialkot",group_series_0:"THQ Sambrial"},
  {group:"Toba Tek Singh",group_series_0:"DHQ Hospital Toba Tek Singh"},
  {group:"Toba Tek Singh",group_series_0:"Govt. Eye-cum-General Hospital Gojra"},
  {group:"Toba Tek Singh",group_series_0:"THQ Hospital Kamalia"},
  {group:"Vehari",group_series_0:"BHU 1/WB"},
  {group:"Vehari",group_series_0:"BHU 122/WB"},
  {group:"Vehari",group_series_0:"BHU 155/WB"},
  {group:"Vehari",group_series_0:"BHU 158/WB"},
  {group:"Vehari",group_series_0:"BHU 187/WB"},
  {group:"Vehari",group_series_0:"BHU 22/WB"},
  {group:"Vehari",group_series_0:"BHU 44/kb"},
  {group:"Vehari",group_series_0:"BHU 477/EB"},
  {group:"Vehari",group_series_0:"BHU 5/WB"},
  {group:"Vehari",group_series_0:"BHU 535/EB"},
  {group:"Vehari",group_series_0:"BHU 569/EB"},
  {group:"Vehari",group_series_0:"BHU 69/WB"},
  {group:"Vehari",group_series_0:"BHU 88/WB"},
  {group:"Vehari",group_series_0:"BHU FATEH PUR"},
  {group:"Vehari",group_series_0:"BHU QADAR ABAD"},
  {group:"Vehari",group_series_0:"BHU SAHAR"},
  {group:"Vehari",group_series_0:"BHU SARGANA"},
  {group:"Vehari",group_series_0:"DHQ Hospital Vehari"},
  {group:"Vehari",group_series_0:"RHC 188/EB MIAN PAKHI"},
  {group:"Vehari",group_series_0:"RHC Garah More"},
  {group:"Vehari",group_series_0:"RHC TIBBA SULTAN PUR"},
  {group:"Vehari",group_series_0:"THQ Burewala"},
  {group:"Vehari",group_series_0:"THQ Mailsi"}
  ];

export const mapDummyData = [
  {
    "type": "FeatureCollection",
    "features": [
      {
        "type": "Feature",
        "geometry": {
          "type": "Point",
          "coordinates": [102.0, 0.5]
        },
        "properties": {
          "prop0": "value0"
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "LineString",
          "coordinates": [
            [102.0, 0.0],
            [103.0, 1.0],
            [104.0, 0.0],
            [105.0, 1.0]
          ]
        },
        "properties": {
          "prop0": "value0",
          "prop1": 0.0
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [100.0, 0.0],
              [101.0, 0.0],
              [101.0, 1.0],
              [100.0, 1.0],
              [100.0, 0.0]
            ]
          ]
        },
        "properties": {
          "prop0": "value0",
          "prop1": { "this": "that" }
        }
      }
    ]
  }
];