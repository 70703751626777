import React from 'react';
import { GridComponent, ColumnsDirective, ColumnDirective, Toolbar, Search, Resize, Sort, ContextMenu, Filter, Page, Edit, Inject } from '@syncfusion/ej2-react-grids';
import { diseasesGrid, waterborneData } from '../../data/dummy';
import { Header, Button, Navbar } from '../../components';
import { Link } from 'react-router-dom';
import { FiSettings } from 'react-icons/fi';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { useStateContext } from '../../contexts/ContextProvider';
import { Category, ChartComponent, ColumnSeries, DataLabel, Legend, LineSeries, SeriesCollectionDirective, SeriesDirective } from '@syncfusion/ej2-react-charts';

const WaterBorne = () => {
  const {
    setCurrentColor,
    setCurrentMode,
    currentMode,
    activeMenu,
    currentColor,
    themeSettings,
    setThemeSettings,
  } = useStateContext();
/*
  useEffect(() => {
    const currentThemeColor = localStorage.getItem('colorMode');
    const currentThemeMode = localStorage.getItem('themeMode');
    if (currentThemeColor && currentThemeMode) {
      setCurrentColor(currentThemeColor);
      setCurrentMode(currentThemeMode);
    }
  }, []);
  */
  const filterSettings = { type: 'Excel' };
  return (
    <div className='m-2 md:m-6 p-2 md:p-6 bg-white rounded-3xl'>    
    {/* <div className='fixed right-4 bottom-4' style={{ zIndex: '1000' }}> */}
    {/* <TooltipComponent content='Settings' position='Top'>
      <button
        type='button'
        onClick={() => setThemeSettings(true)}
        style={{ background: currentColor, borderRadius: '50%' }}
        className='text-3xl text-white p-3 hover:drop-shadow-xl hover:bg-light-gray'
      >
        <FiSettings />
      </button>
    </TooltipComponent> */}
  {/* </div> */}
    <div className='fixed md:static bg-main-bg dark:bg-main-dark-bg navbar w-full '>
    <Navbar />
      </div>
      <Header category='Disease' title='Water Borne Diseases' />        
      
      <div className='mb-2'>
      <Link to={'/waterborne/amebiases'}>
      <Button className='bg-white text-gray-500 hover:text-white' text='Amebiasis' bgHoverColor='gray-500' />
      </Link>
      <Link to={'/waterborne/choleras'}>
      <Button className='bg-white text-gray-500 hover:text-white' text='Cholera' bgHoverColor='gray-500' />
      </Link>
      <Link to={'/waterborne/hepatitises'}>
      <Button className='bg-white text-gray-500 hover:text-white' text='Hepatitis (A E)' bgHoverColor='gray-500' />
      </Link>
      <Link to={'/waterborne/typhoids'}>
      <Button className='bg-white text-gray-500 hover:text-white' text='Typhoid' bgHoverColor='gray-500' />
      </Link>
      </div>
      <GridComponent id='gridcomp'
      dataSource={waterborneData}
      allowFiltering={true}
      filterSettings={filterSettings}
      toolbar={['Search']}
      width='auto'
      >
      <ColumnsDirective>
        {diseasesGrid.map((item, index) => (
        /* eslint-disable react/jsx-props-no-spreading */
        <ColumnDirective key={index} {... item}/>
      ))}
      </ColumnsDirective>
      <Inject services={[Search, Toolbar, Resize, Sort, ContextMenu, Filter, Page, Edit]} />
    </GridComponent>
    <div className='container text-2xl mt-8'>
        <ChartComponent
        title='Water Borne Diseases'
        width='600px'
        primaryXAxis={{valueType:"Category", title:"Disease"}}
        primaryYAxis={{title:"Total"}} legendSettings={{visible:true}}
        >
          <Inject services={[ColumnSeries, LineSeries, Category, Legend, DataLabel]}/>
          <SeriesCollectionDirective>
            <SeriesDirective
            type='Column'
            dataSource={waterborneData}
            xName='Disease'
            yName='Total'
            name='Diseases'
            marker={{dataLabel:{visible:true}, visible:true}}>

            </SeriesDirective>
          </SeriesCollectionDirective>
        </ChartComponent>
      </div>
    </div>

  );
};
export default WaterBorne;
