import React, {Component} from 'react';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Toolbar,
  Search,
  Resize,
  Sort,
  ContextMenu,
  Filter,
  Page,
  Edit,
  Inject,
} from '@syncfusion/ej2-react-grids';
import { entriesGrid } from '../../data/dummy';
import { Header, Button } from '../../components';
import axios from 'axios';
import { Category, ChartComponent, ColumnSeries, DataLabel, Legend, LineSeries, SeriesCollectionDirective, SeriesDirective } from '@syncfusion/ej2-react-charts';
import { Tooltip } from '@syncfusion/ej2-react-popups';
import Maps from '../../components/Maps';

const CCHF = props => (
  <tr>
    <td>{props.cchf.username}</td>
    <td>{props.cchf.description}</td>
    <td>{props.cchf.duration}</td>
    <td>{props.cchf.date.substring(0,10)}</td>
  </tr>
)
export default class CCHFs extends Component{
  constructor(props)
  {
    super(props);
    this.state = {cchf :[]};
  }
  
  Tabs = ({ color }) => {
    const [openTab, setOpenTab] = React.useState(1);
    return (
      <>
        <div className="flex flex-wrap">
          <div className="w-full">
            <ul
              className="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row"
              role="tablist"
            >
              <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
                <a
                  className={
                    "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                    (openTab === 1
                      ? "text-white bg-teal-600"
                      : "text-teal-600 bg-white")
                  }
                  onClick={e => {
                    e.preventDefault();
                    setOpenTab(1);
                  }}
                  data-toggle="tab"
                  href="#link1"
                  role="tablist"
                >
                  Data Table
                </a>
              </li>
              <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
                <a
                  className={
                    "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                    (openTab === 2
                      ? "text-white bg-teal-600"
                      : "text-teal-600 bg-white")
                  }
                  onClick={e => {
                    e.preventDefault();
                    setOpenTab(2);
                  }}
                  data-toggle="tab"
                  href="#link2"
                  role="tablist"
                >
                   Charts
                </a>
              </li>
              <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
                <a
                  className={
                    "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                    (openTab === 3
                      ? "text-white bg-teal-600"
                      : "text-teal-600 bg-white")
                  }
                  onClick={e => {
                    e.preventDefault();
                    setOpenTab(3);
                  }}
                  data-toggle="tab"
                  href="#link3"
                  role="tablist"
                >
                   Map
                </a>
              </li>
            </ul>
            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
              <div className="px-4 py-5 flex-auto">
                <div className="tab-content tab-space">
                  <div className={openTab === 1 ? "block" : "hidden"} id="link1">
                  {/*
                  Tab 1
                  */}
                  <GridComponent 
                  id='cchf'
                  dataSource={this.state.cchf}
                  allowFiltering={true}
                  allowPaging={true}
                  filterSettings={this.filterSettings}
                  width='auto'>
                    <ColumnsDirective>
                      {entriesGrid.map((item, index) => (
                      /* eslint-disable react/jsx-props-no-spreading */
                      
                      <ColumnDirective key={index} {... item}/>
                    ))}
                    <Inject
                      services={[
                      Filter,
                      Page,
                      Search,
                    ]}
                  />
                    </ColumnsDirective>
                  </GridComponent>
                  </div>
                  <div className={openTab === 2 ? "block" : "hidden"} id="link2">
                    {/*
                    Tab 2
                    */}

                        <iframe width="640" height="480" src="https://charts.mongodb.com/charts-project-0-oudwg/embed/charts?id=b09922e5-325b-48b7-ab0d-f356347457ce&maxDataAge=3600&theme=light&autoRefresh=true"></iframe>
                        <iframe width="640" height="480" src="https://charts.mongodb.com/charts-project-0-oudwg/embed/charts?id=220407d2-5cd9-4e19-90ca-ed99d881f270&maxDataAge=3600&theme=light&autoRefresh=true"></iframe>
                        <iframe width="640" height="480" src="https://charts.mongodb.com/charts-project-0-oudwg/embed/charts?id=220407d2-5cd9-4e19-90ca-ed99d881f270&maxDataAge=3600&theme=light&autoRefresh=true"></iframe>
                  </div>
                  <div className={openTab === 3 ? "block" : "hidden"} id="link3">
                    {/**
                     * Tab 3
                     */}
                     <Maps/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  componentDidMount(){
    axios.get('https://api.pakonehealth.org/cchfs')
    .then(response => {
      this.setState({cchf: response.data});
      console.log("received data");

    })
    .catch((error) => {
      console.log(error);
      console.log("Not receiving data");
    })
  }

  entriesList(){
    return this.state.cchf.map(currentEntry => {
      return <CCHF cchf={currentEntry} />;
    })
  }

  filterSettings = { type: 'Excel' };
  fields = { text: 'text', value: 'value' };
  format = { type: 'datetime', format: 'M/d/y hh:mm a' };
  render() {
    return (    
    <div className='m-2 md:m-6 p-2 md:p-6 bg-white rounded-3xl'>
    <Header category='VectorBorne Diseases' title='CCHF' />
      <this.Tabs color={'teal'}/>
        </div>
    )
  }
}